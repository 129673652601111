import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const DeleteLetterHead = async (fileName) => {
	const url = `${BASE_URL}/api/org/letterhead/delete`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			fileName,
		}),
	});

	if (res.ok) {
		return {
			status: true,
		};
	}

	return { status: false };
};

export default DeleteLetterHead;
