import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../UiKit/Button";
import { Heading } from "../../UiKit/Heading";
import { TbFileUpload } from "react-icons/tb";
import FilePreview from "../../UiKit/FilePreview";
import Modal from "../../UiKit/Modal";
import uploadAdditional from "../../data/files/uploadAdditional";
import getAdditionalFiles from "../../data/files/getAdditionalFiles";
import { AiOutlineLoading } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import deleteAdditionalFile from "../../data/files/deleteAdditionalFile";

const FileUploadPanel = ({ isCompletedPA, denialId }) => {
	const [file, setFile] = useState(null);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [isUploadingFile, setIsUploadingFile] = useState(false);

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const toggleUploadModal = () => setIsUploadModalOpen(!isUploadModalOpen);

	const [fileToDelete, setFileToDelete] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeletingFile, setIsDeletingFile] = useState(false);
	const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

	const handleFileSelect = (e) => {
		const selectedFile = e.target.files[0];
		setFile(selectedFile);
		setIsUploadModalOpen(true);
	};

	const [isFetchingFiles, setIsFetchingFiles] = useState(true);
	const fetchAdditionalFiles = useCallback(async () => {
		const res = await getAdditionalFiles(denialId);
		setIsFetchingFiles(false);

		if (res.status) {
			setUploadedFiles(res.data);
		}
	}, [denialId]);

	const handleFileUpload = async () => {
		setIsUploadingFile(true);
		const reader = new FileReader();

		reader.onloadend = async () => {
			const base64Result = reader.result.split(",")[1];
			const name = file.name;

			const { status } = await uploadAdditional(
				base64Result,
				denialId,
				name
			);

			if (status) {
				await fetchAdditionalFiles();
				setFile(null);
			}

			setIsUploadingFile(false);
			setIsUploadModalOpen(false);
		};

		reader.readAsDataURL(file);
	};

	const deleteFileUpload = async () => {
		setIsDeletingFile(true);
		const { status } = await deleteAdditionalFile(fileToDelete.file_id);

		if (!status) return;

		await fetchAdditionalFiles();

		setIsDeletingFile(false);
		toggleDeleteModal();
	};

	const handleRemoveFile = (file) => () => {
		setFileToDelete(file);
		toggleDeleteModal();
	};

	const renderedFiles = uploadedFiles.map((file, idx) => {
		return (
			<div className="mt-4">
				<FilePreview
					title={file.name}
					key={idx}
					onClose={isCompletedPA ? false : handleRemoveFile(file)}
					variant="white"
				/>
			</div>
		);
	});

	useEffect(() => {
		fetchAdditionalFiles();
	}, [fetchAdditionalFiles]);

	return (
		<div className="w-replyPageRight flex flex-col items-center bg-background1 h-full px-7 overflow-y-scroll pb-7">
			<div className="mt-7 flex flex-col justify-end mb-4">
				<Heading>Attachments</Heading>
				{uploadedFiles.length === 0 && (
					<p className="mx-auto text-center mt-4 text-olive">
						No files attached
					</p>
				)}
			</div>

			{isFetchingFiles && (
				<AiOutlineLoading className="animate-spin text-xl mt-2" />
			)}

			{!isCompletedPA && !isFetchingFiles && (
				<div className="mb-10">
					<Button
						variant="full"
						fullWidth
						onFileUpload={handleFileSelect}
					>
						<TbFileUpload className="text-lg mr-2.5" />
						Click to upload
					</Button>
				</div>
			)}

			{uploadedFiles.length > 0 && (
				<div className="flex flex-col items-center">
					{renderedFiles}
				</div>
			)}

			{file && (
				<Modal
					isOpen={isUploadModalOpen}
					close={toggleUploadModal}
					onSubmit={handleFileUpload}
					title="Upload Attachment"
					description={file.name}
					submitText="Upload"
					loading={isUploadingFile}
					icon={<TbFileUpload className="text-lg" />}
				/>
			)}

			{fileToDelete && (
				<Modal
					isOpen={isDeleteModalOpen}
					close={toggleDeleteModal}
					onSubmit={deleteFileUpload}
					title="Remove Attachment"
					description={fileToDelete.name}
					submitText="Remove"
					loading={isDeletingFile}
					icon={<GrClose className="text-red text-lg" />}
					color="red"
				/>
			)}
		</div>
	);
};
export default FileUploadPanel;
