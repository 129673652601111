import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import TimeoutPopup from "../Components/TimeoutPopup";

const timeout = 300_000;
const promptBeforeIdle = 4_000;

export function useTimeout() {
	const [remaining, setRemaining] = useState(timeout);
	const [showTimeoutAlert, setShowTimeoutAlert] = useState(false);
	const navigate = useNavigate();

	const onPrompt = () => {
		setShowTimeoutAlert(true);
	};

	const { getRemainingTime, activate } = useIdleTimer({
		onPrompt,
		timeout,
		promptBeforeIdle,
		throttle: 500,
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 500);

		return () => {
			clearInterval(interval);
		};
	});

	const handleAlertButtonClick = () => {
		setShowTimeoutAlert(false);
		setRemaining(timeout);
		activate();
	};

	const handleInactive = () => {
		handleAlertButtonClick();
		localStorage.clear();
		navigate("/");
	};

	// Next steps: display the timeout alert and handle the button click
	const alert = showTimeoutAlert ? (
		<TimeoutPopup
			onClick={handleAlertButtonClick}
			onInactive={handleInactive}
		/>
	) : null;

	useEffect(() => {
		activate();
	}, []);

	return {
		alert,
	};
}
