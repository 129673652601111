import { useRef } from "react";

const TelInput = ({ SetState }) => {
	const phoneInput = useRef();
	const ReformatPhoneNumber = () => {
		let formattedPhoneNumber = FormatPhoneNumber(phoneInput.current.value);
		phoneInput.current.value = formattedPhoneNumber;
		SetState(formattedPhoneNumber.replace(/[^\d]/g, ""));
	};
	const FormatPhoneNumber = (phoneNumber) => {
		if (!phoneNumber) return phoneNumber;

		//Replace non-digit values
		phoneNumber = phoneNumber.replace(/[^\d]/g, "");

		const phoneNumberLength = phoneNumber.length;

		if (phoneNumberLength < 4) return phoneNumber;

		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6, 10)}`;
	};

	return (
		<input
			type="tel"
			inputmode="tel"
			maxlength={14}
			minlength={14}
			onChange={(e) => {
				ReformatPhoneNumber();
				// setModalReplyNumber(val);
			}}
			placeholder="(xxx)-xxx-xxxx"
			ref={phoneInput}
			pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
			className="w-full flex items-center placeholder-olive rounded bg-background1 px-6 py-2.5 focus:outline-none outline-none border-none focus:border-transparent focus:ring-0"
		></input>
	);
};
export default TelInput;
