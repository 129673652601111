import React, { useContext, useEffect, useState } from "react";
import { Heading } from "../../UiKit/Heading";
import Item from "../../UiKit/Item";
import Pagination from "../../UiKit/Pagination";
import useKeypress from "react-use-keypress";
import Loader from "../../UiKit/Loader";
import SearchFilters from "../shared/SearchFilters";
import Case from "../shared/Case";
import SubHeader from "../shared/SubHeader";
import SubHeading from "../shared/SubHeading";
import { UserContext } from "../../contexts/UserContext";
import { usePatientsStore } from "../../stores/patientsStore";
import PatientItem from "../../UiKit/PatientsItem";
import { BsPersonCircle, BsPersonSquare } from "react-icons/bs";

const PatientsPage = () => {
	const { patients, init, loading, error } = usePatientsStore();
	const [activeItemID, setActiveItemID] = useState(0);
	const [displayedItems, setDisplayedItems] = useState(patients);
	const [showAssignedItems, setShowAssignedItems] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const handlePageChange = (newPage) => {
		setActiveItemID(0);
		setCurrentPage(newPage);
	};

	const user = useContext(UserContext);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const renderedInbox = displayedItems
		?.slice(startIndex, endIndex)
		.map(({ patient: { first_name, last_name } }, index) => {
			return (
				<PatientItem
					isActive={index === activeItemID}
					setActiveItemId={setActiveItemID}
					idx={index}
					onSelectItem={() => {}}
					key={index}
				>
					<div className="font-normal text-xs text-ellipsis overflow-hidden flex-1 px-2 whitespace-nowrap flex justify-between my-auto">
						{/* <div className="w-4 h-4 rounded-full flex bg-primary text-xs">
							<div className="mx-auto my-auto text-[8px] flex text-white">
								{first_name[0]}
								{last_name[0]}
							</div>
						</div> */}
						<div className="flex-1">
							<div className="ml-6 justify-start flex">
								<span className="w-1/3">{first_name}</span>
								<span className="w-1/3 mx-auto justify-center text-center">
									{last_name}
								</span>
								<span className="w-1/3 text-right px-12">
									{activeItemID === index && " ->"}
								</span>
							</div>
						</div>
					</div>
				</PatientItem>
			);
		});

	const handleKeyDown = () => {
		let num = activeItemID;
		if (num === patients.length - 1) {
			num = 0;
		} else {
			num = num + 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	const handleKeyUp = () => {
		let num = activeItemID;
		if (num === 0) {
			num = patients.length - 1;
		} else {
			num = num - 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	useKeypress(["ArrowDown"], handleKeyDown);
	useKeypress(["ArrowUp"], handleKeyUp);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (e) => {
		const term = e.target.value;

		setSearchTerm(term);
		const searchIndex = term.length;

		let filteredPatients = patients.filter(
			({ patient: { first_name, last_name } }) => {
				return (
					first_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					last_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					(first_name + " " + last_name)
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase())
				);
			}
		);

		setDisplayedItems(filteredPatients);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (!user) return;
		if (!patients) return;

		let sortedAppeals = [...patients];

		if (showAssignedItems) {
			setDisplayedItems(
				sortedAppeals.filter((appeal) => appeal.assigned_to === user.id)
			);
		} else {
			setDisplayedItems(sortedAppeals);
		}
		// Reset current page to 1 when showAssignedItems is toggled
		setCurrentPage(1);
		setActiveItemID(0);
	}, [patients, showAssignedItems, user]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init]);

	if (loading) {
		return <Loader title="patients" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	return (
		<>
			<div className="flex flex-1 flex-col max-w-6xl mx-auto mt-8 px-10 overflow-y-scroll">
				<div className="flex justify-between items-center mb-4">
					<Heading>Patients</Heading>
				</div>

				<SearchFilters
					searchTerm={searchTerm}
					handleSearch={handleSearch}
					setShowAssignedItems={setShowAssignedItems}
					showAssignedItems={showAssignedItems}
					hideShowAssignedItems
				/>

				<div className="bg-slate-100 flex flex-row justify-between text-gray-800 mb-1">
					<div className="text-sm flex-1 font-semibold w-full my-auto">
						<span className="ml-6">First name</span>
					</div>
					<div className="text-sm flex-1 font-semibold text-center w-1/3 my-auto">
						Last name
					</div>
					<div className="flex flex-1 justify-end w-1/3"></div>
				</div>

				{renderedInbox}

				<Pagination
					totalItems={displayedItems?.length}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>

			<RightBar
				item={displayedItems ? displayedItems[activeItemID] : null}
			/>
		</>
	);
};

const RightBar = ({ item }) => {
	if (!item)
		return (
			<div className="h-screen bg-background1 overflow-y-scroll px-8 w-1/3">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>
				<p className="text-olive mt-4">Nothing to display</p>
			</div>
		);

	const renderedCases = item.cases.map((caseInfo) => {
		return (
			<Case
				timeline={caseInfo.timeline}
				requestedMedicationOrService={
					caseInfo.case.requested_medication_or_service
				}
				patient={item.patient}
				caseId={caseInfo.case.id}
			/>
		);
	});

	const DOB = new Date(item.patient.date_of_birth);

	return (
		<div className="h-screen text-sm bg-background1 overflow-y-scroll px-10 pb-10 w-2/5">
			<div className="mt-8">
				<Heading>
					{item.patient.first_name} {item.patient.last_name}
				</Heading>
			</div>

			{/* <SubHeader>Patient details</SubHeader> */}

			{/* <SubHeading>Address</SubHeading>
			<p>{item.patient.address}</p>

			<SubHeading>DOB</SubHeading>
			<p>{DOB.toDateString()}</p> */}

			{renderedCases.length === 0 && (
				<>
					<SubHeader>Prior auth cases</SubHeader>
					<p className="text-olive mt-4">No cases to display</p>
				</>
			)}
			{renderedCases}
		</div>
	);
};

export default PatientsPage;
