import React from "react";

export const NavigationItem = ({
	isActive,
	icon,
	name,
	onClick,
	disabled = false,
}) => {
	return (
		<div
			onClick={onClick}
			className={`
                hover:bg-background2
                duration-75 
                mb-2.5
				w-full
                rounded-[0.5px]
                text-sm
                px-6
				py-2
                text-left 
                cursor-pointer 
                flex
				items-center
                ${isActive && " bg-background2"}
                ${disabled && " opacity-50 cursor-not-allowed"}
            `}
		>
			<span className="my-auto mr-2.5">{icon}</span> {name}
		</div>
	);
};
