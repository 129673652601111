import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const FilePreview = ({ title, onClose = false, variant = "base" }) => {
	const variants = {
		base: "w-40 h-52 px-4 bg-background1 flex items-center justify-content-center relative",
		white: "w-40 h-52 px-4 bg-white flex items-center justify-content-center relative shadow",
	};

	if (!onClose)
		return (
			<div className={variants[variant]}>
				<p className="text-sm text-olive text-center w-full">{title}</p>
			</div>
		);

	return (
		<div className={variants[variant]}>
			<p className="text-sm text-olive text-center w-full break-words">
				{title}
			</p>
			<button onClick={onClose}>
				<IoIosCloseCircle className="text-red absolute -top-3 -left-3 text-3xl" />
			</button>
		</div>
	);
};

export default FilePreview;
