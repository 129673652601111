import React from "react";
import SubHeader from "../shared/SubHeader";
import SubHeading from "../shared/SubHeading";
import Timeline from "./Timeline";
import StatusIndicator from "./StatusIndicator";

const Case = ({ timeline, requestedMedicationOrService, patient, caseId }) => {
	if (!timeline || !timeline.length) return null;

	const renderedTimeline = timeline.map((event, index) => {
		let uniqueKey = `${event.created_at}-${index}`;

		if (event.type === "denial") {
			if (event.is_appeal_sent) {
				return (
					<>
						{/* For the appeal, instead of supplying the faxId, need to offer the stored appeal PDF we create */}
						<Timeline
							type="appeal"
							date={event.created_at}
							key={uniqueKey}
							faxId={event.id}
							patient={patient}
						/>

						<Timeline
							type={event.type}
							date={event.created_at}
							key={uniqueKey + "-2"}
							faxId={event.inbound_fax_id}
							caseId={caseId}
							patientId={patient.patient_id}
						/>
					</>
				);
			}
		}

		return (
			<Timeline
				type={event.type}
				date={event.created_at}
				key={uniqueKey}
				faxId={event.inbound_fax_id}
				caseId={caseId}
				patientId={patient.patient_id}
			/>
		);
	});

	return (
		<div className="border px-4 py-4 border-6 border-gray-300 rounded-md bg-white/90 mt-4 text-sm">
			<div className="font-bold">
				{requestedMedicationOrService} prior authorization
			</div>

			<SubHeading>Status</SubHeading>
			<div className="mt-4">
				{timeline[0].type === "denial" && timeline[0].is_appeal_sent ? (
					<StatusIndicator status="pending" />
				) : (
					<StatusIndicator status={timeline[0].type} />
				)}
			</div>

			<SubHeading>Timeline</SubHeading>
			{renderedTimeline}
		</div>
	);
};

export default Case;
