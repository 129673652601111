import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotificationProvider from "./hooks/useNotify";
import AccountPage from "./pages/SideBarPages/AccountPage";
import HomePage from "./pages/SideBarPages/HomePage";
import LoginPage from "./pages/LiSu/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";
import TrialUpload from "./pages/TrialUpload";
import WaitlistPage from "./pages/WaitlistPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { UserProvider } from "./contexts/UserContext";
import { Timeout } from "./Components/Timeout";
import "react-toastify/dist/ReactToastify.css";
import EmailSignupPage from "./pages/signupEmailPage/emailSignupPage";
import LetterPage from "./pages/SideBarPages/LetterPage";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const App = () => {
	return (
		<BrowserRouter>
			<NotificationProvider>
				<Routes>
					<Route element={<Timeout />}>
						<Route
							path="/home"
							element={
								<UserProvider>
									<HomePage />
								</UserProvider>
							}
						></Route>
						<Route
							path="/account"
							element={
								<UserProvider>
									<AccountPage />
								</UserProvider>
							}
						></Route>
						<Route
							path="/reply"
							element={
								<UserProvider>
									<LetterPage />
								</UserProvider>
							}
						></Route>
					</Route>
					<Route path="/join/*" element={<EmailSignupPage />}></Route>
					<Route path="/signup" element={<SignupPage />}></Route>
					<Route path="/tryitout" element={<TrialUpload />}></Route>

					<Route path="/" element={<LoginPage />}></Route>
					<Route path="/waitlist" element={<WaitlistPage />} />
					<Route path="/privacy" element={<PrivacyPolicy />} />
				</Routes>
			</NotificationProvider>
		</BrowserRouter>
	);
};
export default App;
