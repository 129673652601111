import React from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";

const UploadPlaceholder = ({ fileName, setCanUpload }) => {
	return (
		<div className="w-full h-full flex flex-col mx-auto z-0">
			<div className="w-24 h-32 rounded-sm border border-gray-500 flex flex-col mx-auto relative">
				<div
					className=""
					onClick={() => {
						setCanUpload(false);
					}}
				>
					<XCircleIcon
						className="absolute -top-2 -left-3 bg-red-200 rounded-full w-8 h-8 flex cursor-pointer text-red-400"
						aria-hidden="true"
					/>
				</div>
				<div>.....</div>
				<div>.....</div>
				<div>.....</div>
			</div>
			<div className="mx-auto text-gray-500 text-sm">{fileName}</div>
		</div>
	);
};
export default UploadPlaceholder;
