// Will track state of the form and will pass it child components.
// Will include error and submit logic

import React, { useState } from "react";
import { Button } from "../../UiKit/Button";
import { Input } from "../../UiKit/Input";
import SignupForm from "./SignupForm";
import { ToastContainer, toast } from "react-toastify";
import { IoPeople, IoPerson } from "react-icons/io5";
import signup from "../../data/user/signup";
import { useNavigate } from "react-router-dom";
import login from "../../data/user/login";
import { AiOutlineLoading } from "react-icons/ai";

const SignupPage = () => {
	const navigate = useNavigate();
	const handleBackToLogin = () => navigate("/");

	const [step, setStep] = useState(1);
	const incrementStep = () => setStep(step + 1);
	const handleGoBack = () => setStep(step - 1);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [title, setTitle] = useState("");

	const [practice, setPractice] = useState("");
	const [orgSelect, setOrgSelect] = useState(null);
	const handleStartingMyOwnClick = () => setOrgSelect("startingMyOwn");
	const handleJoiningOneClick = () => setOrgSelect("joiningOne");

	const validateEmail = (email) =>
		/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

	const handleEmailPasswordSubmit = (e) => {
		e.preventDefault();

		if (!validateEmail(email)) {
			toast("Invalid email", { type: "error" });
			return;
		}

		if (password.length < 8) {
			toast("Password must be at least 8 characters long", {
				type: "error",
			});
			return;
		}

		incrementStep();
	};

	const handlePersonalDetailsSubmit = (e) => {
		e.preventDefault();

		if (firstName.length < 2) {
			toast("First name must be at least 2 characters long", {
				type: "error",
			});
			return;
		}

		if (lastName.length < 2) {
			toast("Last name must be at least 2 characters long", {
				type: "error",
			});
			return;
		}

		if (title.length < 2) {
			toast("Title must be at least 2 characters long", {
				type: "error",
			});
			return;
		}

		incrementStep();
	};

	const handleOrgSubmit = (e) => {
		e.preventDefault();

		if (!orgSelect) {
			toast("Please select an option", { type: "error" });
			return;
		}

		if (orgSelect === "startingMyOwn") {
			if (practice.length < 2) {
				toast("Practice name must be at least 2 characters long", {
					type: "error",
				});
				return;
			}
		}

		if (orgSelect === "joiningOne") {
			if (practice.length < 2) {
				toast("Join code must be at least 2 characters long", {
					type: "error",
				});
				return;
			}
		}

		handleSubmit();
	};

	const [orgSubmitLoading, setOrgSubmitLoading] = useState(false);
	const handleSubmit = async () => {
		setOrgSubmitLoading(true);
		const { status } = await signup(
			email,
			password,
			title,
			"",
			firstName,
			lastName,
			practice,
			orgSelect === "joiningOne",
			practice
		);

		if (status) {
			const didLogin = await login(email, password);
			setOrgSubmitLoading(false);
			if (didLogin.status) {
				navigate("/home");
			} else {
				toast("Error logging in after sign up", { type: "error" });
				return;
			}
		} else {
			setOrgSubmitLoading(false);
			toast("Error signing up", { type: "error" });
			return;
		}
	};

	return (
		<div className="w-full h-screen flex items-center justify-center bg-gray-100">
			<ToastContainer />

			{step === 1 && (
				<SignupForm
					step={step}
					title="Welcome! First things first..."
					handleSubmit={handleEmailPasswordSubmit}
				>
					<div className="mb-4 w-80">
						<Input
							variant="search"
							placeholder="Email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>

					<div className="mb-4 w-80">
						<Input
							variant="search"
							placeholder="Password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
					</div>

					<div className="mb-4 w-80">
						<Button
							variant="full"
							fullWidth
							type="submit"
							form={step}
						>
							Next
						</Button>
					</div>

					<div className="mb-4 w-80 flex flex-col w-full items-center">
						<Button
							variant="outline"
							fullWidth
							onClick={handleBackToLogin}
						>
							{"<"}- Back to login
						</Button>
					</div>
				</SignupForm>
			)}

			{step === 2 && (
				<SignupForm
					step={step}
					handleGoBack={handleGoBack}
					handleSubmit={handlePersonalDetailsSubmit}
					title="A few personal details..."
				>
					<div className="mb-4 w-80">
						<Input
							variant="search"
							placeholder="First name"
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>
					</div>

					<div className="mb-4 w-80">
						<Input
							variant="search"
							placeholder="Last name"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>
					</div>

					<div className="mb-4 w-80">
						<Input
							variant="search"
							placeholder="Title"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required
						/>
					</div>

					<div className="mb-4 w-80">
						<Button
							variant="full"
							fullWidth
							type="submit"
							form={step}
						>
							Next
						</Button>
					</div>
				</SignupForm>
			)}

			{step === 3 && (
				<SignupForm
					step={step}
					handleGoBack={handleGoBack}
					handleSubmit={handleOrgSubmit}
					title="Are you joining an organization, or starting your own?"
				>
					<div className="w-96 flex justify-between">
						<div
							className={`mb-4 w-40 h-40 rounded bg-babyBlue flex flex-col items-center justify-center cursor-pointer ${
								orgSelect === "startingMyOwn" &&
								"outline outline-primary shadow-md"
							}`}
							onClick={handleStartingMyOwnClick}
						>
							<IoPerson size={80} />
							<p>Starting my own</p>
						</div>

						<div
							className={`mb-4 w-40 h-40 rounded bg-babyBlue flex flex-col items-center justify-center cursor-pointer ${
								orgSelect === "joiningOne" &&
								"outline outline-primary shadow-md"
							}`}
							onClick={handleJoiningOneClick}
						>
							<IoPeople size={80} />
							<p>Joining one</p>
						</div>
					</div>

					{orgSelect && (
						<div className="mb-4 w-80">
							<Input
								variant="search"
								placeholder={
									orgSelect === "startingMyOwn"
										? "Practice name"
										: "Enter join code"
								}
								type="text"
								value={practice}
								onChange={(e) => setPractice(e.target.value)}
								required
							/>
						</div>
					)}

					<div className="mb-4 w-80">
						<Button
							variant="full"
							fullWidth
							type="submit"
							form={step}
							disabled={orgSubmitLoading}
						>
							{orgSubmitLoading && (
								<AiOutlineLoading className="animate-spin text-xl my-auto mr-4" />
							)}{" "}
							Submit
						</Button>
					</div>
				</SignupForm>
			)}
		</div>
	);
};

export default SignupPage;
