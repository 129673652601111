import React, { useEffect, useState } from "react";
import getDenial from "../../data/v2/getDenial";
import { useSearchParams } from "react-router-dom";
import Loader from "../../UiKit/Loader";
import ReplyPanel from "../../Components/ReplyPage/ReplyPanel";
import LetterComponent from "../../Components/ReplyPage/LetterComponent";
import FileUploadPanel from "../../Components/ReplyPage/FileUploadPanel";
import { IoCloseOutline } from "react-icons/io5";

const LetterPage = () => {
	const [denial, setDenial] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [denialId, setDenialId] = useState(null);
	const [searchParams] = useSearchParams();
	const [shouldShowDenial, setShouldShowDenial] = useState(false);

	useEffect(() => {
		for (const entry of searchParams.entries()) {
			const [param, value] = entry;

			if (param === "denialId") setDenialId(value);
		}
	}, [searchParams]);

	useEffect(() => {
		const initialize = async () => {
			if (denialId !== null) {
				const res = await getDenial(denialId);
				setLoading(false);

				if (!res.status) {
					setError(res.data);
					return;
				}

				setDenial(res.data.data);
			}
		};

		initialize();
	}, [denialId]);

	if (loading) {
		return <Loader title="letter editor" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	return (
		<div className="w-full flex flex-row overflow-y-none h-screen relative">
			<ReplyPanel
				authorizationCase={denial.case}
				patient={denial.patient}
				denial={denial.denial}
				setShouldShowDenial={setShouldShowDenial}
				denialLetter={denial.fax.fax_base64}
			/>

			<div className="flex-1 overflow-y-scroll flex">
				{!shouldShowDenial && (
					<LetterComponent
						authorizationCase={denial.case}
						patient={denial.patient}
						denial={denial.denial}
					/>
				)}

				{shouldShowDenial && (
					<div className="w-full relative">
						<div
							className="left-6 top-6 text-2xl bg-red text-white duration-300 rounded-full absolute cursor-pointer hover:bg-red-600 p-2 z-50"
							onClick={() => {
								setShouldShowDenial(false);
							}}
						>
							<IoCloseOutline />
						</div>

						<embed
							showToolbar={false}
							src={`data:application/pdf;base64,${denial.fax.fax_base64}#zoom=80&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
							className="mx-auto my-auto shadow-2xl w-full h-full"
						/>
					</div>
				)}
			</div>

			<FileUploadPanel isCompletedPA={false} denialId={denialId} />
		</div>
	);
};

export default LetterPage;
