import fetch from "unfetch";
import { BASE_URL } from "../../globals";
/**
 *
 * @returns
 */
const getAssignableUsers = async () => {
	if (localStorage.getItem("auth")) {
		const url = `${BASE_URL}/api/org/assignable`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				authorization: `${localStorage.getItem("auth")}`,
			},
		});

		if (res.ok) {
			const data = await res.json();
			console.log(data);
			return { status: true, data: data };
		} else {
			return { status: false };
		}
	}
};
export default getAssignableUsers;
