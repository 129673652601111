import { create } from "zustand";
import getFaxes from "../data/v2/getFaxes";

export const useFaxesStore = create((set) => ({
	faxes: [],
	setFaxes: (faxes) => set((state) => ({ faxes })),
	error: null,
	loading: true,
	init: async () => {
		try {
			const response = await getFaxes();
			const { data } = response;

			set((state) => ({
				faxes: data,
				error: null,
				loading: false,
			}));
		} catch (error) {
			set((state) => ({ error: error.message, loading: false }));
		}
	},
}));
