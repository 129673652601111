import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const UpdatePractice = async (practiceName) => {
	const url = `${BASE_URL}/api/org/name/update`;
	const res = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({ practiceName }),
	});

	if (res.ok) {
		return {
			status: true,
		};
	} else {
		return { status: false };
	}
};

export default UpdatePractice;
