import { create } from "zustand";
import GetLetterHead from "../data/org/GetLetterHead";
import DeleteLetterHead from "../data/org/DeleteLetterHead";
import UploadLetterHead from "../data/org/UploadLetterHead";

let letterHeadCache = null;

export const useLetterHeadStore = create((set, get) => ({
	letterHead: letterHeadCache,
	error: null,
	isFetchingLetterhead: false,
	isUploading: false,
	isRemovingLetterHead: false,
	hasFetched: false, // Add this line
	setIsUploading: (isUploading) =>
		set((state) => ({ isUploading: isUploading })),
	setIsFetchingLetterhead: (isFetching) =>
		set((state) => ({ isFetchingLetterhead: isFetching })),
	setIsRemovingLetterHead: (isRemoving) =>
		set((state) => ({ isRemovingLetterHead: isRemoving })),
	setLetterHead: (data) => {
		letterHeadCache = {
			filePath: data.letterHead,
			fileName: data.fileName,
		};
		set((state) => ({
			letterHead: letterHeadCache,
			error: null,
		}));
	},
	setLetterHeadError: (error) => set((state) => ({ error: error.message })),
	init: async () => {
		if (
			!letterHeadCache &&
			!get().isFetchingLetterhead &&
			!get().hasFetched
		) {
			set((state) => ({ isFetchingLetterhead: true }));

			try {
				const { data, status } = await GetLetterHead();

				if (!status) {
					set((state) => ({
						isFetchingLetterhead: false,
						hasFetched: true,
					}));
					return;
				}

				const { letterHead, fileName } = data;
				if (!letterHead || !fileName) {
					set((state) => ({
						isFetchingLetterhead: false,
						hasFetched: true,
					}));
					return;
				}

				letterHeadCache = {
					fileName: data.fileName,
					letterHead: data.letterHead,
				};

				set((state) => ({
					letterHead: letterHeadCache,
					error: null,
					isFetchingLetterhead: false,
					hasFetched: true, // Add this line
				}));
			} catch (error) {
				set((state) => ({
					error: error.message,
					isFetchingLetterhead: false,
					hasFetched: true, // Add this line
				}));
			}
		}
	},
	handleUpload: async (imagePath, fileName) => {
		set((state) => ({ isUploading: true }));

		if (imagePath) {
			const reader = new FileReader();
			reader.onloadend = async () => {
				var base64result = reader.result.split(",")[1];

				const uploadResponse = await UploadLetterHead(
					base64result,
					fileName
				);

				if (uploadResponse.status) {
					set((state) => ({
						letterHead: {
							fileName: fileName,
							filePath: base64result,
						},
						isUploading: false,
					}));
				} else {
					set((state) => ({ isUploading: false }));
				}
			};

			console.log("IMAGE PATH", imagePath);
			reader.readAsDataURL(imagePath);
		} else {
			set((state) => ({ isUploading: false }));
		}
	},
	handleDeleteLetterHead: async () => {
		const { letterHead } = get();

		if (!letterHead) return;

		set((state) => ({ isRemovingLetterHead: true }));

		const { status } = await DeleteLetterHead(letterHead.fileName);

		set((state) => ({ isRemovingLetterHead: false }));

		if (!status) return;

		set((state) => ({ letterHead: null }));
	},
}));
