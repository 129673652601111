import React, { useContext, useEffect, useState } from "react";
import { Heading } from "../../UiKit/Heading";
import Item from "../../UiKit/Item";
import Pagination from "../../UiKit/Pagination";
import useKeypress from "react-use-keypress";
import Loader from "../../UiKit/Loader";
import SearchFilters from "../shared/SearchFilters";
import DateIcon from "../shared/DateIcon";
import { UserContext } from "../../contexts/UserContext";
import { usePriorAuthorizationDenials } from "../../stores/priorAuthorizationDenialsStore";
import { Button } from "../../UiKit/Button";
import Modal from "../../UiKit/Modal";
import SubHeading from "../shared/SubHeading";
import Upload from "./Upload";
import abandonCase from "../../data/v2/abandonCase";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { useNavigate } from "react-router";
import AssignModal from "../../UiKit/AssignModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import OutsideClickHandler from "react-outside-click-handler";

const DenialsPage = () => {
	const [activeItemID, setActiveItemID] = useState(0);
	const [showAssignedItems, setShowAssignedItems] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [ascending, setAscending] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [dotsOption, setDotsOption] = useState(false);
	const [dotsIndex, setDotsIndex] = useState(null);

	const closeDots = () => {
		console.log("closing");
		setDotsIndex(null);
		setDotsOption(false);
	};

	const {
		prior_authorization_denials: denials,
		init,
		error,
		loading,
	} = usePriorAuthorizationDenials();
	const [displayedItems, setDisplayedItems] = useState(denials);
	const { user } = useContext(UserContext);
	const navigate = useNavigate();

	const itemsPerPage = 10;

	const handlePageChange = (newPage) => {
		setActiveItemID(0);
		setCurrentPage(newPage);
	};

	const handleToggleDate = () => {
		setAscending(!ascending);
		setDisplayedItems((prevItems) => {
			return [...prevItems].sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return ascending ? dateA - dateB : dateB - dateA;
			});
		});
	};

	const handleDenialClick = (denialId) => {
		const path = `/reply?denialId=${denialId}`;

		navigate(path);
	};

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const handleKeyDown = () => {
		let num = activeItemID;
		if (num === denials.length - 1) {
			num = 0;
		} else {
			num = num + 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	const handleKeyUp = () => {
		let num = activeItemID;
		if (num === 0) {
			num = denials.length - 1;
		} else {
			num = num - 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	useKeypress(["ArrowDown"], handleKeyDown);
	useKeypress(["ArrowUp"], handleKeyUp);
	// useEffect(() => {
	// 	setDotsOption(false);
	// }, [activeItemID]);
	const handleSearch = (e) => {
		const term = e.target.value;

		setSearchTerm(term);
		const searchIndex = term.length;

		let filteredDenials = denials.filter(
			({ patient: { first_name, last_name } }, index) => {
				return (
					first_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					last_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					(first_name + " " + last_name)
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase())
				);
			}
		);

		setDisplayedItems(filteredDenials);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (!user) return;
		if (!denials) return;

		let sortedAppeals = [...denials];
		if (!ascending) {
			sortedAppeals.sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return dateB - dateA;
			});
		}

		if (showAssignedItems) {
			setDisplayedItems(
				sortedAppeals.filter(
					({ denialData: { assigned_to } }) => assigned_to === user.id
				)
			);
		} else {
			setDisplayedItems(sortedAppeals);
		}
		// Reset current page to 1 when showAssignedItems is toggled
		setCurrentPage(1);
		setActiveItemID(0);
	}, [denials, showAssignedItems, user, ascending]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init]);

	if (loading) {
		return <Loader title="denials" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	const renderedInbox = displayedItems
		?.slice(startIndex, endIndex)
		.map(
			(
				{
					denialData: {
						created_at,
						g_requested_medication_or_service,
						id,
					},
					patient: { first_name, last_name },
				},
				index
			) => {
				const date = new Date(created_at);
				return (
					<Item
						type={"denial"}
						setDotsItem={setDotsOption}
						isActive={index === activeItemID}
						setActiveItemId={setActiveItemID}
						idx={index}
						onSelectItem={() => {}}
					>
						<div
							onClick={() => {
								handleDenialClick(id);
							}}
							className="font-normal text-sm text-ellipsis overflow-hidden flex-1 whitespace-nowrap flex justify-between my-auto"
						>
							<div className="flex-1">
								<div className="ml-6">
									{first_name} {last_name}
									{activeItemID === index && " ->"}
								</div>
							</div>

							<div className="flex-1 text-center">
								{g_requested_medication_or_service}
							</div>

							<div className="flex-1 flex justify-end">
								<div className="mr-6">
									{date.toDateString()}
								</div>
							</div>
						</div>
						<OutsideClickHandler
							onOutsideClick={() => {
								setDotsIndex(null);
								setDotsOption(false);
							}}
						>
							<div className="relative z-50">
								<BsThreeDotsVertical
									className=""
									onClick={() => {
										console.log("Three dots");
										setDotsIndex(index);
										setDotsOption(true);
									}}
								/>

								<div
									className={`${
										dotsOption && index === dotsIndex
											? " block"
											: " hidden "
									}transition transistion-all duration-[500] font-normal w-32 text-sm py-1 text-gray-600 flex flex-col absolute bg-white border border-gray-200 rounded-md shadow-lg right-2 z-50`}
								>
									<div className="hover:bg-gray-200 hover:text-primary px-2 h-6 flex">
										<span
											className="my-auto"
											onClick={() => {}}
										>
											Download
										</span>
									</div>
									<div
										onClick={() => {
											handleDenialClick(id);
										}}
										className="hover:bg-gray-200 hover:text-primary px-2 h-6 flex"
									>
										<span className="my-auto">View</span>
									</div>
								</div>
							</div>
						</OutsideClickHandler>
					</Item>
				);
			}
		);

	return (
		<>
			<div className="flex flex-1 flex-col max-w-6xl mx-auto mt-8 px-10 overflow-y-scroll">
				<div className="flex justify-between items-center mb-4">
					<Heading>Denials</Heading>
					<Upload />
				</div>

				<SearchFilters
					searchTerm={searchTerm}
					handleSearch={handleSearch}
					setShowAssignedItems={setShowAssignedItems}
					showAssignedItems={showAssignedItems}
				/>

				<div className="bg-slate-100 flex flex-row justify-between text-gray-800 ">
					<div className="text-sm flex-1 font-semibold w-full my-auto">
						<span className="ml-6">Patient name</span>
					</div>
					<div className="text-sm flex-1 font-semibold text-center w-1/3 my-auto">
						Denied treatment
					</div>
					<div className="flex flex-1 justify-end w-1/3">
						<button
							className="flex cursor-pointer h-full space-x-2 mr-6"
							onClick={handleToggleDate}
						>
							<p className="text-sm font-semibold text-center my-auto">
								Date denied
							</p>
							<span className="my-auto">
								<DateIcon ascending={ascending} />
							</span>
						</button>
					</div>
				</div>
				<div className=" border-gray-500 mt-1">{renderedInbox}</div>
				<Pagination
					totalItems={displayedItems?.length}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>

			<RightBar
				item={displayedItems ? displayedItems[activeItemID] : null}
			/>
		</>
	);
};

const RightBar = ({ item }) => {
	const [isAbandonPriorAuthModalOpen, setIsAbandonPriorAuthModalOpen] =
		useState(false);
	const [isAbandoningPriorAuth, setIsAbandoningPriorAuth] = useState(false);

	const toggleAbandonPriorAuthModalOpen = () =>
		setIsAbandonPriorAuthModalOpen(!isAbandonPriorAuthModalOpen);

	const [isRecategorizeModalOpen, setIsRecategorizeModalOpen] =
		useState(false);
	const toggleRecategorizeModal = () =>
		setIsRecategorizeModalOpen(!isRecategorizeModalOpen);

	const { init } = usePriorAuthorizationDenials();
	const navigate = useNavigate();

	const handleDenialClick = (denialId) => {
		const path = `/reply?denialId=${denialId}`;

		navigate(path);
	};

	if (!item)
		return (
			<div className="h-screen bg-background1 overflow-y-scroll px-10 relative w-sidebarRight">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>
				<p className="text-olive mt-4">Nothing to display</p>
			</div>
		);

	const {
		patient: { first_name, last_name },
		denialData: {
			g_requested_medication_or_service,
			g_denial_reason,
			case_id,
			denial_id,
			id,
		},
	} = item;
	console.log(item);
	const handleAbandonPriorAuth = async () => {
		setIsAbandoningPriorAuth(true);
		const res = await abandonCase(case_id);

		if (!res.status) {
			setIsAbandoningPriorAuth(false);
			toast.error("Something went wrong");
		}

		await init();
		setIsAbandoningPriorAuth(false);
		toast.success("Abandoned prior auth");
		toggleAbandonPriorAuthModalOpen();
	};

	return (
		<div className="h-screen bg-background1 overflow-y-scroll px-10 relative w-sidebarRight">
			<div className="mt-8">
				<Heading>At a glance</Heading>
			</div>

			<SubHeading>Patient name</SubHeading>
			<p>
				{first_name} {last_name}
			</p>

			<SubHeading>Denied treatment</SubHeading>
			<p>{g_requested_medication_or_service}</p>

			<SubHeading>Denial reason</SubHeading>
			<p>{g_denial_reason}</p>

			<SubHeading>Actions</SubHeading>
			<div className="mb-4">
				<Button
					variant="blue"
					onClick={() => {
						handleDenialClick(id);
					}}
				>
					<AiOutlineEye className="text-xl mr-2" />
					View denial
				</Button>
			</div>

			<div className="mb-4">
				<Button
					variant="blue"
					onClick={() => {
						console.log("Clicked");
						toggleRecategorizeModal();
					}}
				>
					<svg
						fill="none"
						viewBox="0 0 24 24"
						height="24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
						className="w-4 fill-white mr-2"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M11.5144 2.12584C11.8164 1.95805 12.1836 1.95805 12.4856 2.12584L21.4856 7.12584C21.8031 7.30221 22 7.63683 22 8C22 8.36317 21.8031 8.69779 21.4856 8.87416L12.4856 13.8742C12.1836 14.0419 11.8164 14.0419 11.5144 13.8742L2.51436 8.87416C2.19689 8.69779 2 8.36317 2 8C2 7.63683 2.19689 7.30221 2.51436 7.12584L11.5144 2.12584ZM5.05913 8L12 11.856L18.9409 8L12 4.14396L5.05913 8Z"
						></path>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M2.12584 11.5144C2.39405 11.0316 3.00286 10.8576 3.48564 11.1258L12 15.856L20.5144 11.1258C20.9971 10.8576 21.6059 11.0316 21.8742 11.5144C22.1424 11.9971 21.9684 12.6059 21.4856 12.8742L12.4856 17.8742C12.1836 18.0419 11.8164 18.0419 11.5144 17.8742L2.51436 12.8742C2.03157 12.6059 1.85763 11.9971 2.12584 11.5144Z"
						></path>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M2.12584 15.5144C2.39405 15.0316 3.00286 14.8576 3.48564 15.1258L12 19.856L20.5144 15.1258C20.9971 14.8576 21.6059 15.0316 21.8742 15.5144C22.1424 15.9971 21.9684 16.6059 21.4856 16.8742L12.4856 21.8742C12.1836 22.0419 11.8164 22.0419 11.5144 21.8742L2.51436 16.8742C2.03157 16.6059 1.85763 15.9971 2.12584 15.5144Z"
						></path>
					</svg>
					Recategorize denial
				</Button>
			</div>

			<Button variant="red" onClick={toggleAbandonPriorAuthModalOpen}>
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-white mr-2"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
					></path>
				</svg>
				Abandon prior auth
			</Button>

			<AssignModal
				isModalOpen={isRecategorizeModalOpen}
				toggleModal={toggleRecategorizeModal}
				caseId={item.denialData.case_id}
				patientId={item.patient.patient_id}
				type="denial"
				inbound_fax_id={item.denialData.inbound_fax_id}
			/>

			<Modal
				isOpen={isAbandonPriorAuthModalOpen}
				close={toggleAbandonPriorAuthModalOpen}
			>
				<div className="w-loginWidth flex flex-col">
					<Heading center>Confirm abandon prior auth</Heading>

					<div className="bg-red w-16 h-16 flex items-center justify-center rounded-full my-4 self-center">
						<svg
							fill="none"
							viewBox="0 0 24 24"
							height="24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
							className="w-16 fill-white"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 11.5C9.55228 11.5 10 11.0523 10 10.5C10 9.94772 9.55228 9.5 9 9.5C8.44772 9.5 8 9.94772 8 10.5C8 11.0523 8.44772 11.5 9 11.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M11 10.5C11 9.94772 11.4477 9.5 12 9.5H15C15.5523 9.5 16 9.94772 16 10.5C16 11.0523 15.5523 11.5 15 11.5H12C11.4477 11.5 11 11.0523 11 10.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5H15C15.5523 14.5 16 14.0523 16 13.5C16 12.9477 15.5523 12.5 15 12.5H12Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5H15C15.5523 17.5 16 17.0523 16 16.5C16 15.9477 15.5523 15.5 15 15.5H12Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M10 13.5C10 14.0523 9.55228 14.5 9 14.5C8.44772 14.5 8 14.0523 8 13.5C8 12.9477 8.44772 12.5 9 12.5C9.55228 12.5 10 12.9477 10 13.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 17.5C9.55228 17.5 10 17.0523 10 16.5C10 15.9477 9.55228 15.5 9 15.5C8.44772 15.5 8 15.9477 8 16.5C8 17.0523 8.44772 17.5 9 17.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 2C8.44772 2 8 2.44772 8 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3H16C16 2.44772 15.5523 2 15 2H9ZM16 5H18V20H6V5H8V6C8 6.55228 8.44772 7 9 7H15C15.5523 7 16 6.55228 16 6V5ZM10 5V4H14V5H10Z"
							></path>
						</svg>
					</div>

					<p className="mt-4 text-olive text-base text-center">
						Make sure you want to give up on this prior
						authorization
					</p>

					<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
						<Button
							variant="red-outline"
							onClick={toggleAbandonPriorAuthModalOpen}
							disabled={isAbandoningPriorAuth}
						>
							Cancel
						</Button>
						<Button
							variant="red"
							onClick={handleAbandonPriorAuth}
							disabled={isAbandoningPriorAuth}
						>
							{isAbandoningPriorAuth && (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							)}
							Abandon
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default DenialsPage;
