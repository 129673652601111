import React from "react";

const FileUpload = ({ canUpload, uploadFile }) => {
	return (
		<div
			className={`flex items-center ${
				canUpload ? "hidden" : "block"
			} justify-center w-full`}
		>
			<label
				for="dropzone-file"
				className="flex flex-col items-center justify-center w-full h-64 border-2 border-background3 border-dashed rounded-lg cursor-pointer bg-background1 hover:bg-gray-100 "
			>
				<div className="flex flex-col items-center justify-center pt-5 pb-6">
					<svg
						aria-hidden="true"
						className="w-10 h-10 mb-3"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
						></path>
					</svg>
					<p className="mb-2 text-sm">
						<span className="font-semibold">Click to upload</span>
					</p>
					<p className="text-sm">PDF or PNG</p>
				</div>
				<input
					id="dropzone-file"
					onChange={uploadFile}
					accept="application/pdf"
					type="file"
					className="hidden"
				/>
			</label>
		</div>
	);
};

export default FileUpload;
