import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const updateReplyLetter = async (denialId, replyLetter) => {
	console.log("Updating reply letter:", replyLetter);

	const url = `${BASE_URL}/api/denial/replyLetter/update`;
	const res = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({
			id: denialId,
			reply_letter: replyLetter,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false, data: "Error in fetch." };
	}
};

export default updateReplyLetter;
