import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const inviteUser = async (email) => {
	const url = `${BASE_URL}/api/org/invite`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({ invites: [email] }),
	});

	if (res.ok) {
		console.log("ALL GOOD");
		return { status: true };
	} else {
		console.log("ALL BAD");
		return { status: false, data: "Error in fetch." };
	}
};

export default inviteUser;
