import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import Lottie from "react-lottie-player";
import loading_anim from "../assets/loading.json";
const Loader = ({ title }) => (
	<div className="w-full h-screen bg-white flex">
		<div className="mx-auto my-auto flex flex-col">
			<Lottie
				className="mx-auto"
				animationData={loading_anim}
				play
				style={{ width: 70, height: 70 }}
			/>
			<div className="mt-8 mx-auto ">Loading {title}...</div>
		</div>
	</div>
);

export default Loader;
