import React, { useState } from "react";
import OpenDropdownIcon from "./OpenDropdownIcon";
import SubHeading from "./SubHeading";
import { Button } from "../../UiKit/Button";
import getFax from "../../data/v2/getFax";
import { AiOutlineLoading } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import getAppeal from "../../data/v2/getAppeal";
import { ToastContainer, toast } from "react-toastify";
import AssignModal from "../../UiKit/AssignModal";

const Timeline = ({ type, date, faxId, caseId, patientId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [fax, setFax] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const toggleOpen = () => setIsOpen(!isOpen);

	const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
	const toggleModal = () => setIsAssignModalOpen(!isAssignModalOpen);

	const handleClickView = async () => {
		setIsFetching(true);

		if (type === "appeal") {
			const { data, status } = await getAppeal(faxId);
			if (status) {
				setFax(data.data.appeal.appeal_base64);
			} else {
				toast.error("Error fetching appeal");
			}
		} else {
			const { data, status } = await getFax(faxId);
			if (status) {
				setFax(data.data.fax_base64);
			} else {
				toast.error(`Error fetching ${type}`);
			}
		}
		setIsFetching(false);
	};

	const Open = () => (
		<>
			<SubHeading>Actions</SubHeading>
			<Button
				variant="blue"
				onClick={(e) => {
					e.stopPropagation();
					handleClickView();
				}}
			>
				{isFetching ? (
					<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
				) : (
					<ViewIcon />
				)}
				View {type}
			</Button>

			{type !== "appeal" && (
				<>
					<div className="mt-4">
						<Button
							variant="blue"
							onClick={(e) => {
								e.stopPropagation();
								toggleModal();
							}}
						>
							<svg
								fill="none"
								viewBox="0 0 24 24"
								height="24"
								width="24"
								xmlns="http://www.w3.org/2000/svg"
								className="w-4 fill-white mr-2"
							>
								<path
									xmlns="http://www.w3.org/2000/svg"
									d="M11.5144 2.12584C11.8164 1.95805 12.1836 1.95805 12.4856 2.12584L21.4856 7.12584C21.8031 7.30221 22 7.63683 22 8C22 8.36317 21.8031 8.69779 21.4856 8.87416L12.4856 13.8742C12.1836 14.0419 11.8164 14.0419 11.5144 13.8742L2.51436 8.87416C2.19689 8.69779 2 8.36317 2 8C2 7.63683 2.19689 7.30221 2.51436 7.12584L11.5144 2.12584ZM5.05913 8L12 11.856L18.9409 8L12 4.14396L5.05913 8Z"
								></path>
								<path
									xmlns="http://www.w3.org/2000/svg"
									d="M2.12584 11.5144C2.39405 11.0316 3.00286 10.8576 3.48564 11.1258L12 15.856L20.5144 11.1258C20.9971 10.8576 21.6059 11.0316 21.8742 11.5144C22.1424 11.9971 21.9684 12.6059 21.4856 12.8742L12.4856 17.8742C12.1836 18.0419 11.8164 18.0419 11.5144 17.8742L2.51436 12.8742C2.03157 12.6059 1.85763 11.9971 2.12584 11.5144Z"
								></path>
								<path
									xmlns="http://www.w3.org/2000/svg"
									d="M2.12584 15.5144C2.39405 15.0316 3.00286 14.8576 3.48564 15.1258L12 19.856L20.5144 15.1258C20.9971 14.8576 21.6059 15.0316 21.8742 15.5144C22.1424 15.9971 21.9684 16.6059 21.4856 16.8742L12.4856 21.8742C12.1836 22.0419 11.8164 22.0419 11.5144 21.8742L2.51436 16.8742C2.03157 16.6059 1.85763 15.9971 2.12584 15.5144Z"
								></path>
							</svg>
							Recategorize {type}
						</Button>
					</div>

					<AssignModal
						isModalOpen={isAssignModalOpen}
						toggleModal={toggleModal}
						caseId={caseId}
						patientId={patientId}
						type={type}
						inbound_fax_id={faxId}
					/>
				</>
			)}
		</>
	);

	const formattedDate = new Date(date);

	return (
		<div className="flex flex-col justify-between mb-4">
			<ToastContainer />
			{fax && (
				<div
					className="w-full h-full absolute top-0 left-0 relative"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
					}}
				>
					<div
						className="left-6 top-6 text-sm bg-red text-white duration-300 rounded-full absolute cursor-pointer hover:bg-red-600 p-2 z-50"
						onClick={() => {
							setFax(null);
						}}
					>
						<IoCloseOutline />
					</div>

					<embed
						showToolbar={false}
						src={`data:application/pdf;base64,${fax}#zoom=80&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
						className="mx-auto my-auto shadow-2xl w-full h-full"
					/>
				</div>
			)}

			<p className="w-48 mt-2">{formattedDate.toDateString()}</p>
			<div
				className="bg-white rounded border border-navyBlue border-px px-4 py-2 flex-1 mt-2"
				onClick={toggleOpen}
			>
				<div className="flex justify-between">
					<div className="flex items-center">
						<p className="capitalize">{type}</p>
					</div>

					<OpenDropdownIcon open={isOpen} onClick={toggleOpen} />
				</div>

				{isOpen && <Open />}
			</div>
		</div>
	);
};

const ViewIcon = () => (
	<svg
		fill="none"
		viewBox="0 0 24 24"
		height="24"
		width="24"
		xmlns="http://www.w3.org/2000/svg"
		className="w-6 fill-white mr-2"
	>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
		></path>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
		></path>
	</svg>
);

export default Timeline;
