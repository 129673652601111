import create from "zustand";
import getAssignableUsers from "../data/data/getAssignableUsers";

const useAssignableUsersStore = create((set, get) => ({
	assignableUsers: [],
	isLoading: false,
	lastFetch: null,

	init: async () => {
		const { assignableUsers, isLoading, lastFetch } = get();

		if (!isLoading && (!assignableUsers || shouldRefresh(lastFetch))) {
			set({ isLoading: true });

			const response = await getAssignableUsers();
			if (response.status) {
				set({ assignableUsers: response.data, lastFetch: new Date() });
			}

			set({ isLoading: false });
		}
	},
}));

const shouldRefresh = (lastFetch) => {
	if (!lastFetch) return true;

	const now = new Date();
	const timeSinceLastFetch = now - lastFetch;

	// Refresh if more than 5 minutes have passed
	return timeSinceLastFetch > 5 * 60 * 1000;
};

export default useAssignableUsersStore;
