import React, { useEffect, useState } from "react";
import {
	AiOutlineLoading3Quarters,
	AiFillInfoCircle,
	AiOutlineCloseCircle,
} from "react-icons/ai";
import upload from "../data/trial/TestUpload.js";
import ITLogo from "../assets/logo.svg";
import UploadPlaceholder from "../Components/upload/UploadPlaceholder";
import TestGenLetter from "../data/trial/TestGenLetter.js";
import logo from "../assets/logo.svg";
import DetectedContent from "../Components/ReplyPage/DetectedContent.js";
import LetterComponent from "../Components/ReplyPage/LetterComponent.js";
import FileUpload from "../Components/FileUpload.js";

const TrialUpload = () => {
	const [imagePath, setImagePath] = useState("");
	const [canUpload, setCanUpload] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [fileMeta, setFileMeta] = useState(null);
	const [toDispData, setToDispData] = useState(null);
	const [genReplyLoading, setGenReplyLoading] = useState(false);
	const [showReqBeta, setShowReqBeta] = useState(false);
	const [replyLetter, setReplyLetter] = useState(null);
	const [threeOptions, setThreeOptions] = useState(null);
	const [text, setText] = useState("");
	const [optionsIndex, setOptionsIndex] = useState(0);
	const [showPossibleReply, setShowPossibleReply] = useState(false);
	const [possibleReply, setPossibleReply] = useState("");
	const [patientFname, setPatientFname] = useState(null);
	const [patientLname, setPatientLname] = useState(null);
	const generateReply = async () => {
		setGenReplyLoading(true);
		const replyData = await TestGenLetter(
			toDispData?.gpt_data?.quoteReasonsForDenial,
			toDispData?.f_id,
			toDispData?.originalRequest,
			toDispData?.unifyText
		);
		console.log(replyData.data);
		setReplyLetter(replyData?.data?.reply_letter);
		setThreeOptions(replyData?.data?.three_options);
		setGenReplyLoading(false);
	};
	const uploadFile = (event) => {
		let file = event.target.files[0];
		setFileMeta(file);
		console.log(file);

		setImagePath(event.target.files[0]);
		setCanUpload(true);
	};

	const handleClick = () => {
		setIsLoading(true);
		if (imagePath) {
			let h;
			const reader = new FileReader();
			reader.onloadend = async () => {
				h = reader.result;
				const upResp = await upload(h);
				if (upResp.status) {
					setToDispData(upResp.data);
					setPatientFname(upResp?.data?.patient_fname);
					setPatientLname(upResp?.data?.patient_lname);
				}
				setIsLoading(false);
			};
			reader.readAsDataURL(imagePath);
		}
	};
	const isLoadingBox = () => {
		return (
			<div className="z-50 w-screen h-screen absolute flex bg-gray-500 backdrop-blur-sm bg-opacity-10">
				<div className="w-1/4 bg-gray-50 border border-gray-300 mx-auto my-auto mt-44 px-4 py-4 flex flex-col shadow-xl rounded-md">
					<div className="mx-auto text-gray-500">Analyzing</div>
					<AiOutlineLoading3Quarters className="mx-auto text-blue-400 animate-spin mt-4 text-2xl" />
					<div className="mx-auto italic text-gray-500 mt-4">
						Do not close this window.
					</div>
				</div>
			</div>
		);
	};
	useEffect(() => {
		console.log(text);
	}, [text]);
	const upOption = () => {
		let newNum;
		if (optionsIndex < 2) {
			newNum = optionsIndex + 1;
		} else {
			newNum = 0;
		}
		setOptionsIndex(newNum);
	};
	return (
		<div className="w-full h-screen flex flex-col relative isolate">
			<div className="absolute top-4 left-4 z-50">
				<div className="flex relative">
					<button
						onMouseEnter={() => {
							setShowReqBeta(true);
						}}
						onMouseLeave={() => {
							setTimeout(() => {
								setShowReqBeta(false);
							}, 100);
						}}
						className={`flex border border-gray-500 rounded-md px-2 py-2 text-white bg-primary`}
					>
						Beta{" "}
						<AiFillInfoCircle className="my-auto text-white ml-4" />
					</button>
					{showReqBeta && (
						<div className="absolute top-8 left-24 z-50 rounded-tl-none rounded-tr-md rounded-b-md p-4 border border-primary bg-gray-100 w-96 shadow-lg">
							This is a free-to-use demonstration of our product.
							To get full access, fill out the Beta Request Form
							here.
						</div>
					)}
				</div>
			</div>
			{/*  */}
			{showPossibleReply && (
				<>
					<div className="w-screen h-screen absolute flex z-50 bg-gray-300 bg-opacity-50 backdrop-blur-sm">
						<div className="mx-auto my-auto w-1/2 bg-white px-4 py-2 border border-gray-300 rounded-sm relative">
							<span
								onClick={() => {
									setShowPossibleReply(false);
								}}
								className="absolute -top-4 -left-4 text-red-400 bg-red-300 rounded-full text-4xl"
							>
								<AiOutlineCloseCircle />
							</span>
							<span className="px-2 py-2 text-sm">
								{possibleReply}
							</span>
						</div>
					</div>
				</>
			)}
			{isLoading && isLoadingBox()}
			{genReplyLoading && isLoadingBox()}
			{!toDispData && (
				<>
					<div className="mx-auto mt-10 flex flex-col items-center">
						<img src={logo} className="w-52" />
						<h2 className="mt-24 text-primary font-bold text-lg">
							Prior Authorization Appeal Demo
						</h2>
						<p className="w-2/3 mt-4">
							At Intelliga Health, we’re automating the prior
							authorization proccess. We’re excited to make Health
							Suite - our PA drafting platform - open to the
							public via this beta.
							<br />
							<br />
							Get started by uploading a prior authorization
							denial. Our secure infrastructure keeps patient data
							confidential and encrypted.
						</p>

						<div className="flex mt-6">
							<a
								className="mr-6 rounded-md px-4 mx-auto py-2 bg-ctaBlue text-white hover:bg-ctaBlue/75"
								type="submit"
								href="/https://sobhannejad.typeform.com/to/Oh2454h0"
							>
								Share beta feedback
							</a>
							<a
								className="rounded-md px-4 mx-auto py-2 bg-primary text-white hover:bg-primary/75"
								type="submit"
								href="https://sobhannejad.typeform.com/to/UtHGYC3v"
							>
								Access the full platform
							</a>
						</div>
					</div>
					<div className="flex flex-col mx-auto mt-16 text-center space-y-2 w-1/2">
						<div className="mx-auto font-bold text-lg mb-8">
							Upload a Prior Authorization Denial (Must be PDF)
						</div>
						<FileUpload
							canUpload={canUpload}
							uploadFile={uploadFile}
						/>
						{canUpload && (
							<div className="w-full h-full flex">
								<UploadPlaceholder
									fileName={fileMeta.name}
									setCanUpload={setCanUpload}
								/>
							</div>
						)}
						<div className="w-full h-2" />
						{canUpload && (
							<button
								onClick={handleClick}
								className="rounded-md bg-ctaBlue px-10 py-1 text-white self-center shadow"
							>
								Upload File
							</button>
						)}
					</div>
				</>
			)}
			{toDispData && (
				<div className="w-full h-full flex flex-row">
					<DetectedContent
						patientFname={patientFname}
						patientLname={patientLname}
						setPatientFname={setPatientFname}
						setPatientLname={setPatientLname}
						toDispData={toDispData}
					/>

					<div className="w-full flex flex-col h-full bg-gray-200 relative">
						<div className="mx-auto mt-12 font-sans text-lg text-primary">
							<img src={ITLogo} />
						</div>
						{replyLetter && !genReplyLoading && (
							<LetterComponent
								patientFname={patientFname}
								patientLname={patientLname}
								toDispData={toDispData}
								threeOptions={threeOptions}
							/>
						)}

						{!replyLetter && !genReplyLoading && (
							<div
								className="px-12 cursor-pointer py-8 mx-auto text-center bg-primary text-white rounded-md my-auto"
								onClick={() => {
									generateReply();
								}}
							>
								Generate Reply
							</div>
						)}
						<div className="absolute bottom-2 flex w-full">
							<div className=" italic flex mx-auto text-center space-x-4">
								<span className="my-auto">
									Currently using trial access. For access to
									the full Beta, contact us here{" "}
								</span>
								<a
									className="bg-primary text-white px-2 py-2 rounded-md"
									href="https://sobhannejad.typeform.com/to/UtHGYC3v"
								>
									Request Beta
								</a>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default TrialUpload;
