import React, { useContext, useEffect, useState } from "react";
import { Heading } from "../../UiKit/Heading";
import Item from "../../UiKit/Item";
import { Button } from "../../UiKit/Button";
import Pagination from "../../UiKit/Pagination";
import Loader from "../../UiKit/Loader";
import SearchFilters from "../shared/SearchFilters";
import DateIcon from "../shared/DateIcon";
import { usePriorAuthorizationApprovalsStore } from "../../stores/priorAuthorizationApprovalsStore";
import { UserContext } from "../../contexts/UserContext";
import useKeypress from "react-use-keypress";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineLoading } from "react-icons/ai";
import getFax from "../../data/v2/getFax";
import { ToastContainer, toast } from "react-toastify";
import AssignModal from "../../UiKit/AssignModal";

const Approvals = () => {
	const { approvals, init, error, loading } =
		usePriorAuthorizationApprovalsStore();
	const [activeItemID, setActiveItemID] = useState(0);
	const [displayedItems, setDisplayedItems] = useState(approvals);
	const [showAssignedItems, setShowAssignedItems] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const handlePageChange = (newPage) => {
		setActiveItemID(0);
		setCurrentPage(newPage);
	};

	const { user } = useContext(UserContext);
	const [ascending, setAscending] = useState(true);

	const handleToggleDate = () => {
		setAscending(!ascending);
		setDisplayedItems((prevItems) => {
			return [...prevItems].sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return ascending ? dateA - dateB : dateB - dateA;
			});
		});
	};

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const renderedInbox = displayedItems
		?.slice(startIndex, endIndex)
		.map(
			(
				{
					approvalData: {
						approved_medication_or_service,
						created_at,
						inbound_fax_id,
					},
					patient: { first_name, last_name },
				},
				index
			) => {
				const date = new Date(created_at);
				return (
					<Item
						type={"approval"}
						isActive={index === activeItemID}
						setActiveItemId={setActiveItemID}
						idx={index}
						onSelectItem={() => {}}
						key={inbound_fax_id}
					>
						<div className="font-normal text-sm text-ellipsis overflow-hidden flex-1 whitespace-nowrap flex justify-between my-auto">
							<div className="flex-1">
								<div className="ml-6">
									{first_name} {last_name}
									{activeItemID === index && " ->"}
								</div>
							</div>

							<div className="flex-1">
								{approved_medication_or_service}
							</div>

							<div className="flex-1 flex justify-end">
								<div className="mr-6">
									{date.toDateString()}
								</div>
							</div>
						</div>
					</Item>
				);
			}
		);

	const handleKeyDown = () => {
		let num = activeItemID;
		if (num === approvals.length - 1) {
			num = 0;
		} else {
			num = num + 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	const handleKeyUp = () => {
		let num = activeItemID;
		if (num === 0) {
			num = approvals.length - 1;
		} else {
			num = num - 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	useKeypress(["ArrowDown"], handleKeyDown);
	useKeypress(["ArrowUp"], handleKeyUp);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (e) => {
		const term = e.target.value;

		setSearchTerm(term);
		const searchIndex = term.length;

		let filteredAppeals = approvals.filter(
			({ patient: { first_name, last_name } }) => {
				return (
					first_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					last_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					(first_name + " " + last_name)
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase())
				);
			}
		);

		setDisplayedItems(filteredAppeals);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (!user) return;
		if (!approvals) return;

		let sortedAppeals = [...approvals];
		if (!ascending) {
			sortedAppeals.sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return dateB - dateA;
			});
		}

		if (showAssignedItems) {
			setDisplayedItems(
				sortedAppeals.filter((appeal) => appeal.assigned_to === user.id)
			);
		} else {
			setDisplayedItems(sortedAppeals);
		}
		// Reset current page to 1 when showAssignedItems is toggled
		setCurrentPage(1);
		setActiveItemID(0);
	}, [approvals, showAssignedItems, user, ascending]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init]);

	if (loading) {
		return <Loader title="approvals" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	return (
		<>
			<div className="flex flex-1 flex-col max-w-6xl mx-auto mt-8 px-10 overflow-y-scroll">
				<div className="flex justify-between items-center mb-4">
					<Heading>Approvals</Heading>
				</div>

				<SearchFilters
					searchTerm={searchTerm}
					handleSearch={handleSearch}
					setShowAssignedItems={setShowAssignedItems}
					showAssignedItems={showAssignedItems}
				/>

				<div className="bg-slate-100 flex flex-row justify-between text-gray-800 ">
					<div className="text-sm flex-1 font-semibold w-full my-auto">
						<span className="ml-6">Patient name</span>
					</div>
					<div className="text-sm flex-1 font-semibold text-center w-1/3 my-auto">
						Approved treatment
					</div>
					<div className="flex flex-1 justify-end w-1/3">
						<button
							className="flex cursor-pointer h-full space-x-2 mr-6"
							onClick={handleToggleDate}
						>
							<p className="text-sm font-semibold text-center my-auto">
								Date approved
							</p>
							<span className="my-auto">
								<DateIcon ascending={ascending} />
							</span>
						</button>
					</div>
				</div>

				{renderedInbox}
				<Pagination
					totalItems={displayedItems?.length}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>

			<RightBar
				item={displayedItems ? displayedItems[activeItemID] : null}
			/>
		</>
	);
};

const RightBar = ({ item }) => {
	const [approval, setApproval] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
	const toggleModal = () => setIsAssignModalOpen(!isAssignModalOpen);

	if (!item)
		return (
			<div className="h-screen bg-background1 overflow-y-scroll px-10 relative w-sidebarRight">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>
				<p className="text-olive mt-4">Nothing to display</p>
			</div>
		);

	const {
		approvalData: {
			approved_medication_or_service,
			created_at,
			inbound_fax_id,
		},
		patient: { first_name, last_name },
	} = item;

	const handleViewApproval = async () => {
		setIsFetching(true);
		const { status, data } = await getFax(inbound_fax_id);
		setIsFetching(false);

		if (status) {
			setApproval(data.data.fax_base64);
		} else {
			toast.error("Error fetching approval");
		}
	};

	const date = new Date(created_at);

	return (
		<>
			<ToastContainer />

			<div className="h-screen bg-background1 overflow-y-scroll px-10 relative w-sidebarRight">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>

				<SubHeading>Patient name</SubHeading>
				<p>
					{first_name} {last_name}
				</p>

				<SubHeading>Approved treatment</SubHeading>
				<p>{approved_medication_or_service}</p>

				<SubHeading>Date received</SubHeading>
				<p>{date.toDateString()}</p>

				<SubHeading>Actions</SubHeading>

				<div className="mb-4">
					<Button variant="blue" onClick={toggleModal}>
						<svg
							fill="none"
							viewBox="0 0 24 24"
							height="24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
							className="w-4 fill-white mr-2"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M11.5144 2.12584C11.8164 1.95805 12.1836 1.95805 12.4856 2.12584L21.4856 7.12584C21.8031 7.30221 22 7.63683 22 8C22 8.36317 21.8031 8.69779 21.4856 8.87416L12.4856 13.8742C12.1836 14.0419 11.8164 14.0419 11.5144 13.8742L2.51436 8.87416C2.19689 8.69779 2 8.36317 2 8C2 7.63683 2.19689 7.30221 2.51436 7.12584L11.5144 2.12584ZM5.05913 8L12 11.856L18.9409 8L12 4.14396L5.05913 8Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M2.12584 11.5144C2.39405 11.0316 3.00286 10.8576 3.48564 11.1258L12 15.856L20.5144 11.1258C20.9971 10.8576 21.6059 11.0316 21.8742 11.5144C22.1424 11.9971 21.9684 12.6059 21.4856 12.8742L12.4856 17.8742C12.1836 18.0419 11.8164 18.0419 11.5144 17.8742L2.51436 12.8742C2.03157 12.6059 1.85763 11.9971 2.12584 11.5144Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M2.12584 15.5144C2.39405 15.0316 3.00286 14.8576 3.48564 15.1258L12 19.856L20.5144 15.1258C20.9971 14.8576 21.6059 15.0316 21.8742 15.5144C22.1424 15.9971 21.9684 16.6059 21.4856 16.8742L12.4856 21.8742C12.1836 22.0419 11.8164 22.0419 11.5144 21.8742L2.51436 16.8742C2.03157 16.6059 1.85763 15.9971 2.12584 15.5144Z"
							></path>
						</svg>
						Recategorize approval
					</Button>
				</div>

				<Button variant="blue" onClick={handleViewApproval}>
					{isFetching ? (
						<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
					) : (
						<ViewIcon />
					)}
					View approval
				</Button>
			</div>

			<AssignModal
				isModalOpen={isAssignModalOpen}
				toggleModal={toggleModal}
				caseId={item.approvalData.case_id}
				patientId={item.patient.patient_id}
				type="approval"
				inbound_fax_id={item.approvalData.inbound_fax_id}
			/>

			{approval && (
				<div
					className="w-full h-full absolute top-0 left-0 relative"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
					}}
				>
					<div
						className="left-6 top-6 text-2xl bg-red text-white duration-300 rounded-full absolute cursor-pointer hover:bg-red-600 p-2 z-50"
						onClick={() => {
							setApproval(null);
						}}
					>
						<IoCloseOutline />
					</div>

					<embed
						showToolbar={false}
						src={`data:application/pdf;base64,${approval}#zoom=80&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
						className="mx-auto my-auto shadow-2xl w-full h-full"
					/>
				</div>
			)}
		</>
	);
};

const SubHeading = ({ children }) => (
	<p className="uppercase text-olive text-sm mt-4 mb-2">{children}</p>
);

const ViewIcon = () => (
	<svg
		fill="none"
		viewBox="0 0 24 24"
		height="24"
		width="24"
		xmlns="http://www.w3.org/2000/svg"
		className="w-6 fill-white mr-2"
	>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
		></path>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
		></path>
	</svg>
);

export default Approvals;
