import React, { useContext, useEffect, useState } from "react";
import FileUpload from "../../UiKit/FileUpload";
import { UserContext } from "../../contexts/UserContext";
import { Heading } from "../../UiKit/Heading";
import { Button } from "../../UiKit/Button";
import { FiEdit2, FiRefreshCw } from "react-icons/fi";
import CopyableField from "../../UiKit/CopyableField";
import { TbFileUpload } from "react-icons/tb";
import FilePreview from "../../UiKit/FilePreview";
import { Input } from "../../UiKit/Input";
import { AiOutlineLoading } from "react-icons/ai";
import Loader from "../../UiKit/Loader";
import UpdatePractice from "../../data/org/UpdatePractice";
import { useLetterHeadStore } from "../../stores/letterHeadStore";
import useAssignableUsersStore from "../../stores/assignableUsersStore";
import Modal from "../../UiKit/Modal";
import { GrClose } from "react-icons/gr";
import inviteUser from "../../data/v2/inviteUser";
import { ToastContainer, toast } from "react-toastify";

const AccountPage = () => {
	const [file, setFile] = useState(null);
	const { user, refreshUser, updateUser } = useContext(UserContext);
	const {
		letterHead,
		isFetchingLetterhead,
		isUploading,
		isRemovingLetterHead,
		init,
		handleUpload,
		handleDeleteLetterHead,
		hasFetched,
	} = useLetterHeadStore();

	const {
		assignableUsers,
		isLoading,
		init: initializeAssignableUsers,
	} = useAssignableUsersStore();

	const [canEditProfile, setCanEditProfile] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

	const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] =
		useState(false);
	const [inviteMemberEmail, setInviteMemberEmail] = useState("");
	const [isInvitingMember, setIsInvitingMember] = useState(false);

	const toggleInviteMemberModal = () =>
		setIsInviteMemberModalOpen(!isInviteMemberModalOpen);

	const handleInviteNewMember = async () => {
		setIsInvitingMember(true);
		const { status } = await inviteUser(inviteMemberEmail);
		setIsInvitingMember(false);

		if (status) {
			toggleInviteMemberModal();
			toast.success("Invited member");
			setInviteMemberEmail("");
		} else {
			toast.error("Something went wrong");
		}
	};

	const handleUploadClick = async () => {
		await handleUpload(file.file, file.fileName);
	};

	const handleEditProfileClick = async () => {
		const firstName = name.split(" ")[0];
		const lastName = name.split(" ")[1];

		const isProfileUnchanged =
			name === user?.first_name + " " + user?.last_name &&
			email === user?.email;

		if (isProfileUnchanged) {
			setCanEditProfile(!canEditProfile);
			return;
		}

		if (canEditProfile) {
			setIsUpdatingProfile(true);
			await updateUser(firstName, lastName, email);
			setIsUpdatingProfile(false);
		}
		setCanEditProfile(!canEditProfile);
	};

	const [canEditPractice, setCanEditPractice] = useState(false);
	const [practiceName, setPracticeName] = useState("");

	const handlePracticeClick = async () => {
		const isPracticeUnchanged = practiceName === user?.orgs?.org_name;
		if (isPracticeUnchanged) {
			setCanEditPractice(!canEditPractice);
			return;
		}

		if (canEditPractice) {
			await handleUpdatePractice();
		}
		setCanEditPractice(!canEditPractice);
	};

	const [isUpdatingPractice, setIsUpdatingPractice] = useState(false);

	const handleSelectFile = (event) => {
		let uploadedFile = event.target.files[0];
		setFile({ fileName: uploadedFile.name, file: uploadedFile });
	};

	const handleUploadCloseClick = () => setFile(null);

	const handleUpdatePractice = async () => {
		setIsUpdatingPractice(true);

		const { status } = await UpdatePractice(practiceName);

		if (!status) return;
		await refreshUser();

		setIsUpdatingPractice(false);
		setCanEditPractice(false);
	};

	const [isDeleteLetterheadModalOpen, setIsDeleteLetterheadModalOpen] =
		useState(false);
	const toggleDeleteLetterheadModal = () =>
		setIsDeleteLetterheadModalOpen(!isDeleteLetterheadModalOpen);

	const handleDeleteLetterHeadModalSubmit = async () => {
		await handleDeleteLetterHead();
		toggleDeleteLetterheadModal();
	};

	useEffect(() => {
		setName(user?.first_name + " " + user?.last_name);
		setEmail(user?.email);
		setPracticeName(user?.orgs.org_name);
	}, [user]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		if (!letterHead && !isFetchingLetterhead && !hasFetched) {
			initialize();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		initializeAssignableUsers();
	}, [initializeAssignableUsers]);

	const formatFaxNumber = (number) => {
		if (!number) return "";
		return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
	};

	if (!user || isLoading) return <Loader title="account" />;

	const otherUsers = assignableUsers.filter((u) => u.id !== user.id);

	return (
		<div className="flex flex-col px-12 overflow-y-scroll flex-1 max-w-6xl py-8">
			<ToastContainer />
			{/* Profile Heading */}
			<div className="w-full flex justify-between items-start">
				<Heading>Profile</Heading>
				<Button
					variant="blue"
					onClick={handleEditProfileClick}
					disabled={isUpdatingProfile}
				>
					{canEditProfile ? (
						<>
							{isUpdatingProfile ? (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							) : (
								<FiRefreshCw className="mr-2.5" />
							)}
							Save Profile
						</>
					) : (
						<>
							<FiEdit2 className="mr-2.5" />
							Edit Profile
						</>
					)}
				</Button>
			</div>

			{/* Name & Email */}
			<div className="flex w-full space-between mt-4">
				<div className="flex-1">
					<p className="block text-olive mb-2.5 text-sm">Name</p>

					{canEditProfile ? (
						<Input
							type="search"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					) : (
						<p>
							{user.first_name} {user.last_name}
						</p>
					)}
				</div>
				<div className="ml-10 flex-1">
					<p className="block text-olive mb-2.5 text-sm">Email</p>

					{canEditProfile ? (
						<Input
							type="search"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							fullWidth
						/>
					) : (
						<p>{user.email}</p>
					)}
				</div>
			</div>

			{/* Practice Details Heading */}
			<div className="w-full flex justify-between mt-10 items-start">
				<div className="flex flex-col">
					<Heading>Practice Details</Heading>
					<div className="text-xs italic text-gray-400">
						{user?.org_id}
					</div>
				</div>
				<Button
					variant="blue"
					onClick={handlePracticeClick}
					disabled={isUpdatingPractice}
				>
					{canEditPractice ? (
						<>
							{isUpdatingPractice ? (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							) : (
								<FiRefreshCw className="mr-2.5" />
							)}
							Save Practice
						</>
					) : (
						<>
							<FiEdit2 className="mr-2.5" /> Edit practice
						</>
					)}
				</Button>
			</div>

			{/* Name & Add Code */}
			<div className="flex w-full space-between mt-4">
				<div className="flex-1">
					<p className="block text-olive mb-2.5 text-sm">Name</p>

					{canEditPractice ? (
						<Input
							type="search"
							value={practiceName}
							onChange={(e) => setPracticeName(e.target.value)}
							fullWidth
						/>
					) : (
						<p>{user?.orgs?.org_name}</p>
					)}
				</div>

				{/* <div className="ml-10 flex-1 flex flex-col items-start">
					<p className="block text-olive mb-2.5 text-sm">Add code</p>
					<CopyableField>{user?.org_id}</CopyableField>
				</div> */}
			</div>

			{/* Fax number */}
			<div className="flex w-full space-between mt-4">
				<div className="flex-1 flex flex-col items-start">
					<p className="block text-olive mb-2.5 text-sm">
						Fax number
					</p>
					<CopyableField>
						{formatFaxNumber(user?.orgs?.fax_number)}
					</CopyableField>
				</div>
				<div className="flex-1" />
			</div>

			{/* Users List */}
			<div className="w-full my-10 flex flex-col">
				<Heading>Users</Heading>
				<div className="mt-6">
					<table className="w-full table-auto border-collapse">
						<thead>
							<tr className="text-left text-gray-600">
								<th className="pb-4 pr-6">Name</th>
								<th className="pb-4 pr-6">Title</th>
								<th className="pb-4">Email</th>
							</tr>
						</thead>
						<tbody>
							{otherUsers.map((user) => (
								<tr
									key={user.id}
									className="border-b border-gray-200 hover:bg-gray-50"
								>
									<td className="py-4 pr-6">
										{user.first_name} {user.last_name}
									</td>
									<td className="py-4 pr-6">{user.title}</td>
									<td className="py-4">{user.email}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="mt-4 self-end">
					<Button variant="blue" onClick={toggleInviteMemberModal}>
						<svg
							fill="none"
							viewBox="0 0 24 24"
							height="24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
							className="w-4 fill-white mr-2"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12 2C12.3788 2 12.725 2.214 12.8944 2.55279L21.8944 20.5528C22.067 20.8978 22.0256 21.3113 21.7882 21.6154C21.5508 21.9195 21.1597 22.0599 20.7831 21.9762L12 20.0244L3.21694 21.9762C2.84035 22.0599 2.44921 21.9195 2.2118 21.6154C1.97439 21.3113 1.93306 20.8978 2.10558 20.5528L11.1056 2.55279C11.275 2.214 11.6212 2 12 2ZM13 18.1978L19.166 19.568L13 7.23607V18.1978ZM11 7.23607L4.83402 19.568L11 18.1978V7.23607Z"
							></path>
						</svg>
						Invite new member
					</Button>
				</div>
			</div>

			<Modal
				isOpen={isInviteMemberModalOpen}
				close={toggleInviteMemberModal}
			>
				<div className="w-loginWidth flex flex-col">
					<Heading center>Invite new member</Heading>
					<div className="mt-4">
						<Input
							value={inviteMemberEmail}
							onChange={(e) =>
								setInviteMemberEmail(e.target.value)
							}
							placeholder="janedoe@gmail.com"
						/>
					</div>

					<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
						<Button
							variant="blue-outline"
							onClick={toggleInviteMemberModal}
							disabled={isInvitingMember}
						>
							Cancel
						</Button>
						<Button
							variant="blue"
							onClick={handleInviteNewMember}
							disabled={isInvitingMember}
						>
							{isInvitingMember && (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							)}
							Send invitation
						</Button>
					</div>
				</div>
			</Modal>

			{/* Letterhead Heading */}
			<div className="w-full flex items-center">
				<Heading>Letterhead</Heading>
			</div>

			{/* Display letterhead when its prev been uploaded */}
			{letterHead && (
				<div className="mt-4 flex justify-betwen items-start">
					<img
						className="w-48 border border-px border-primary"
						src={letterHead.letterHead}
						alt="File preview"
					/>
					<div className="ml-4">
						<Button
							variant="red"
							onClick={toggleDeleteLetterheadModal}
							disabled={isDeleteLetterheadModalOpen}
						>
							Remove
						</Button>
					</div>
				</div>
			)}

			<Modal
				isOpen={isDeleteLetterheadModalOpen}
				close={toggleDeleteLetterheadModal}
				onSubmit={handleDeleteLetterHeadModalSubmit}
				title="Remove Letterhead"
				description="Are you sure you want to remove this letterhead?"
				submitText="Remove"
				loading={isRemovingLetterHead}
				icon={<GrClose className="text-lg" />}
				color="red"
			/>

			{/* Letterhead upload / only display when value of file is null */}
			{!file && !letterHead && !isFetchingLetterhead && (
				<div className="mt-4">
					<FileUpload
						handleFileUpload={handleSelectFile}
						accept="image/*"
					/>
				</div>
			)}

			{/* Letterhead upload / only display when file exists */}
			{file && !letterHead && (
				<div>
					<div className="my-4">
						<FilePreview
							title={file.fileName}
							onClose={handleUploadCloseClick}
						/>
					</div>

					<Button
						variant="full"
						onClick={handleUploadClick}
						disabled={isUploading}
					>
						{isUploading ? (
							<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
						) : (
							<TbFileUpload className="text-lg mr-2.5" />
						)}
						Upload
					</Button>
				</div>
			)}
		</div>
	);
};

export default AccountPage;
