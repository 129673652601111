import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const verifySession = async (username, password) => {
	const url = `${BASE_URL}/api/user/verify`;
	const res = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
	});

	if (res.ok) {
		console.log("User is logged in.");
		return true;
	} else {
		localStorage.clear();

		return false;
	}
};

export default verifySession;
