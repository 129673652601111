import React from "react";
import { Outlet } from "react-router-dom";
import { useTimeout } from "../hooks/useTimeout";

export const Timeout = () => {
	const { alert: TimeoutAlert } = useTimeout();

	return (
		<>
			<Outlet />
			{TimeoutAlert}
		</>
	);
};
