import React, { useContext, useState } from "react";
import AccountPage from "./AccountPage";
import { Logo } from "../../UiKit/Logo";
import { NavigationItem } from "../../UiKit/NavigationItem";
import { Button } from "../../UiKit/Button";
import { useNavigate } from "react-router-dom";
import PendingAppeals from "./PendingAppeals";
import ApprovalsPage from "./ApprovalsPage";
import LostCasesPage from "./LostCasesPage";
import PatientsPage from "./PatientsPage";
import FaxesPage from "./FaxesPage";
import DenialsPage from "./DenialsPage";
import { UserContext } from "../../contexts/UserContext";
import intelligaLogo from "../../assets/logo_nw.svg";
const HomePage = () => {
	const [activePage, setActivePage] = useState(0);
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const handleLogOut = () => {
		localStorage.clear();
		navigate("/");
	};

	const pages = [
		{
			name: "Denials",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M5.68014 7.09436C4.62708 8.44904 4 10.1513 4 12C4 16.4183 7.58172 20 12 20C13.8487 20 15.551 19.3729 16.9056 18.3199L5.68014 7.09436ZM7.09436 5.68014L18.3199 16.9056C19.3729 15.551 20 13.8487 20 12C20 7.58172 16.4183 4 12 4C10.1513 4 8.44904 4.62708 7.09436 5.68014ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
					></path>
				</svg>
			),
			path: "/inbox",
		},
		{
			name: "Awaiting decision",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 6C12.5523 6 13 6.44772 13 7V11.5858L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V7C11 6.44772 11.4477 6 12 6Z"
					></path>
				</svg>
			),
			path: "/pending",
		},
		{
			name: "Approvals",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M4 4C4 2.89543 4.89543 2 6 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4ZM17.5858 8L14 4.41421V8H17.5858ZM12 4L6 4V20H18V10H13C12.4477 10 12 9.55228 12 9V4ZM15.7071 12.2929C16.0976 12.6834 16.0976 13.3166 15.7071 13.7071L11.7071 17.7071C11.3166 18.0976 10.6834 18.0976 10.2929 17.7071L8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929L11 15.5858L14.2929 12.2929C14.6834 11.9024 15.3166 11.9024 15.7071 12.2929Z"
					></path>
				</svg>
			),
			path: "/approvals",
		},
		{
			name: "Lost cases",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
					></path>
				</svg>
			),
			path: "/lost",
		},
		{
			name: "Patients",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4ZM4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8C16 11.3137 13.3137 14 10 14C6.68629 14 4 11.3137 4 8ZM16.8284 3.75736C17.219 3.36683 17.8521 3.36683 18.2426 3.75736C20.5858 6.10051 20.5858 9.8995 18.2426 12.2426C17.8521 12.6332 17.219 12.6332 16.8284 12.2426C16.4379 11.8521 16.4379 11.219 16.8284 10.8284C18.3905 9.26633 18.3905 6.73367 16.8284 5.17157C16.4379 4.78105 16.4379 4.14788 16.8284 3.75736ZM17.5299 16.7575C17.6638 16.2217 18.2067 15.8959 18.7425 16.0299C20.0705 16.3618 20.911 17.2109 21.3944 18.1778C21.8622 19.1133 22 20.1571 22 21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21C20 20.3429 19.8878 19.6367 19.6056 19.0722C19.339 18.5391 18.9295 18.1382 18.2575 17.9701C17.7217 17.8362 17.3959 17.2933 17.5299 16.7575ZM6.5 18C5.24054 18 4 19.2135 4 21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21C2 18.3682 3.89347 16 6.5 16H13.5C16.1065 16 18 18.3682 18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 19.2135 14.7595 18 13.5 18H6.5Z"
					></path>
				</svg>
			),
			path: "/patients",
		},
		{
			name: "Received faxes",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M7 2C6.44772 2 6 2.44772 6 3V7H4C2.89543 7 2 7.89543 2 9V17C2 18.1046 2.89543 19 4 19H6V21C6 21.5523 6.44772 22 7 22H17C17.5523 22 18 21.5523 18 21V19H20C21.1046 19 22 18.1046 22 17V9C22 7.89543 21.1046 7 20 7H18V3C18 2.44772 17.5523 2 17 2H7ZM17 14H7C6.44772 14 6 14.4477 6 15V17H4V9H7H17H20V17H18V15C18 14.4477 17.5523 14 17 14ZM16 7H8V4H16V7ZM5 10V12H8V10H5ZM16 16V20H8V16H16Z"
					></path>
				</svg>
			),
			path: "/faxes",
		},
		{
			name: "Account",
			icon: (
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 fill-navyBlue"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M12 4C11.4477 4 11 4.44772 11 5C11 6.69226 8.95399 7.53974 7.75738 6.34314C7.36686 5.95261 6.73369 5.95261 6.34317 6.34314C5.95265 6.73366 5.95265 7.36683 6.34317 7.75735C7.53982 8.954 6.69223 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C6.69236 13 7.53964 15.0461 6.34311 16.2426C5.95258 16.6332 5.95258 17.2663 6.34311 17.6569C6.73363 18.0474 7.36679 18.0474 7.75732 17.6569C8.9539 16.4603 11 17.3077 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 17.3077 15.046 16.4602 16.2427 17.6568C16.6332 18.0474 17.2664 18.0474 17.6569 17.6568C18.0474 17.2663 18.0474 16.6332 17.6569 16.2426C16.4603 15.0461 17.3077 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C17.3078 11 16.4601 8.95405 17.6568 7.75737C18.0473 7.36684 18.0473 6.73368 17.6568 6.34315C17.2663 5.95263 16.6331 5.95263 16.2426 6.34315C15.046 7.53979 13 6.69219 13 5C13 4.44772 12.5523 4 12 4ZM9.00816 4.77703C9.12224 3.2243 10.4181 2 12 2C13.5819 2 14.8778 3.2243 14.9918 4.77703C16.1704 3.75977 17.9525 3.8104 19.071 4.92894C20.1896 6.04748 20.2402 7.82955 19.2229 9.00816C20.7757 9.12221 22 10.4181 22 12C22 13.5819 20.7757 14.8778 19.223 14.9918C20.2403 16.1704 20.1896 17.9525 19.0711 19.0711C17.9525 20.1896 16.1705 20.2402 14.9918 19.2229C14.8778 20.7757 13.5819 22 12 22C10.4181 22 9.12221 20.7757 9.00816 19.2229C7.82955 20.2402 6.04745 20.1896 4.92889 19.0711C3.81034 17.9525 3.75972 16.1704 4.77702 14.9918C3.2243 14.8778 2 13.5819 2 12C2 10.4181 3.22433 9.12221 4.77709 9.00816C3.75978 7.82955 3.81041 6.04747 4.92896 4.92892C6.0475 3.81038 7.82955 3.75975 9.00816 4.77703Z"
					></path>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10ZM9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157Z"
					></path>
				</svg>
			),
			path: "/account",
		},
	];

	return (
		<div className="w-full h-screen bg-white flex">
			<div className="flex w-sidebarLeft min-w-sidebarLeft h-full flex-col justify-between bg-background1">
				<div>
					<div className="mt-8 mb-4 mx-8">
						{user?.orgs?.logo && (
							<img
								src={user?.orgs?.logo}
								alt="logo"
								className="w-full mb-4 opacity-90"
							/>
						)}
					</div>

					<div className="flex flex-col px-2">
						{pages.map((key, idx) => {
							return (
								<NavigationItem
									onClick={() => setActivePage(idx)}
									isActive={activePage === idx}
									icon={key.icon}
									name={key.name}
								/>
							);
						})}
					</div>
				</div>

				<div className="py-8 px-7 justify-self-end">
					<Button variant="inline" onClick={handleLogOut}>
						Sign out -{">"}
					</Button>
				</div>
			</div>

			<div className="flex flex-1">
				{activePage === 0 && <DenialsPage />}
				{activePage === 1 && <PendingAppeals />}
				{activePage === 2 && <ApprovalsPage />}
				{activePage === 3 && <LostCasesPage />}
				{activePage === 4 && <PatientsPage />}
				{activePage === 5 && <FaxesPage />}
				{activePage === 6 && <AccountPage />}
			</div>
		</div>
	);
};
export default HomePage;
