import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const getFax = async (faxId) => {
	const url = `${BASE_URL}/api/faxes/single`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({ inbound_fax_id: faxId }),
	});

	if (res.ok) {
		let fax = await res.json();
		return { status: true, data: fax };
	} else {
		console.log(res);
		return { status: false, data: "Error in fetch." };
	}
};

export default getFax;
