import React, { useState } from "react";
import updateDenialField from "../../data/data/updateDenialField";
import { GiBrainstorm, GiHealthNormal } from "react-icons/gi";
import { GrSecure } from "react-icons/gr";
import { FiRefreshCw } from "react-icons/fi";
import refresh from "../../data/refresh";
const DetectedContent = ({
	toDispData,
	patientFname,
	patientLname,
	setPatientFname,
	setPatientLname,
	id,
}) => {
	const [firstNameRefresh, setFirstNameRefresh] = useState(false);
	const [lastNameRefresh, setLastNameRefresh] = useState(false);
	const subTitle = "flex mt-2 font-bold text-md text-black";
	const subText = " not-italic font-semibold text-sm text-gray-800";
	const pNameFieldSty =
		"border border-gray-300 rounded-md px-2 py-2 font-semibold shadow-sm text-gray-700";
	const pNameSty =
		"text-md ml-2 px-3 font-defaultText text-blue-500 border-gray-200 border-b-2 ";
	const fieldSty =
		" rounded-md border-[1px] shad border-gray-300 shadow-sm font-semibold text-gray-600  px-2 py-1";

	const refreshField = async (refreshFunc, field_name, to_set) => {
		refreshFunc(true);
		const refreshedData = await refresh(id, field_name);
		to_set(refreshedData?.refreshed);
		refreshFunc(false);
	};
	return (
		<div className="w-2/5 px-4 bg-white overflow-hidden flex flex-col text-md relative z-10 rounded-tr-2xl rounded-br-2xl">
			<div className="font-defaultText text-xl text-center mt-24 text-gray-500 flex w-full font-normal">
				<div className="mx-auto">Denial Information</div>
			</div>
			<div className="mx-auto relative font-defaultText mt-6 overflow-y-scroll flex flex-col">
				<div className={subTitle}>
					<span className={pNameFieldSty}>First name</span>
					<input
						className={pNameSty}
						value={patientFname}
						onChange={(e) => {
							setPatientFname(e.target.value);
						}}
						onBlur={async (e) => {
							await updateDenialField(
								"patient_fname",
								e.target.value,
								id
							);
						}}
					></input>
					<FiRefreshCw
						onClick={async () => {
							await refreshField(
								setFirstNameRefresh,
								"patient_fname",
								setPatientFname
							);
						}}
						className={`${
							firstNameRefresh && "animate-spin"
						} my-auto ml-2 text-gray-400 cursor-pointer text-sm`}
					/>
				</div>
				<div className={subTitle}>
					<span className={pNameFieldSty}>Last name</span>
					<input
						className={pNameSty}
						value={patientLname}
						onChange={(e) => {
							setPatientLname(e.target.value);
						}}
						onBlur={async (e) => {
							await updateDenialField(
								"patient_lname",
								e.target.value,
								id
							);
						}}
					></input>
					<FiRefreshCw
						onClick={async () => {
							await refreshField(
								setLastNameRefresh,
								"patient_lname",
								setPatientLname
							);
						}}
						className={`${
							lastNameRefresh && "animate-spin"
						} my-auto ml-2 text-gray-400 cursor-pointer text-sm`}
					/>
				</div>
				<div className={`${subTitle} mt-8 flex-col w-full`}>
					<div className="mt-2 mb-2">
						<span className={fieldSty}>
							Requested Treatment or Service
						</span>
					</div>
					<span className={subText}>
						{toDispData.gpt_data.originalRequest}
					</span>
				</div>
				<hr className="my-6" />
				<div className={subTitle + " flex-col"}>
					<div>
						<span className={fieldSty}>Denial Reason</span>
					</div>
					<span className={subText + " mt-4"}>
						{toDispData.gpt_data.denialReason}
					</span>
					<div className="mt-4">
						<span className={fieldSty}>Quote From Letter</span>
					</div>

					<span className="font-normal font-serif text-sm overflow-y-scroll pb-4 mt-4">
						{toDispData.gpt_data.quoteReasonsForDenial}
					</span>
				</div>
			</div>
			<div className="absolute bottom-2 flex space-x-4">
				<div className=" rounded-full p-2 bg-red-400">
					<GiHealthNormal className="text-xl rounded-full text-white" />
				</div>
				<div className=" rounded-full p-2 bg-blue-400">
					<GrSecure className="text-xl rounded-full text-white" />
				</div>
				<div className=" rounded-full p-2 bg-teal-400">
					<GiBrainstorm className="text-xl rounded-full text-white" />
				</div>
			</div>
		</div>
	);
};
export default DetectedContent;
