import fetch from "unfetch";

/**
 *
 * @returns
 */
const TestGenLetter = async (quote, fax_id, ailment, unifyText) => {
	const url = `/api/trial/generatereply`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			quote,
			fax_id,
			ailment,
			unifyText,
		}),
	});

	if (res.ok) {
		const data = await res.json();
		console.log(data);
		return { status: true, data: data };
	} else {
		return { status: false };
	}
};
export default TestGenLetter;
