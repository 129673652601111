import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const recategorize = async (
	type,
	patientId,
	caseId,
	patientData,
	inbound_fax_id,
	defaultObj
) => {
	// console.log(
	// 	type,
	// 	patientId,
	// 	caseId,
	// 	patientData,
	// 	inbound_fax_id,
	// 	defaultObj
	// );
	// return { status: false, data: "Error in fetch." };
	const url = `${BASE_URL}/api/recategorize`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({
			type,
			patientId,
			caseId,
			patientData,
			inbound_fax_id,
			defaultObj,
		}),
	});

	if (res.ok) {
		console.log(await res.json());
		return { status: true };
	} else {
		console.log(await res.json());
		return { status: false, data: "Error in fetch." };
	}
};

export default recategorize;
