import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const updatePatientDetails = async (patientId, firstName, lastName) => {
	const url = `${BASE_URL}/api/patient/update`;
	const res = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({
			patient_id: patientId,
			first_name: firstName,
			last_name: lastName,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false, data: "Error in fetch." };
	}
};

export default updatePatientDetails;
