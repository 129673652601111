import React, { useCallback, useEffect, useRef, useState } from "react";
import sendFax from "../../data/faxes/sendFax";
import { Button } from "../../UiKit/Button";
import { Heading } from "../../UiKit/Heading";
import Modal from "../../UiKit/Modal";
import htmlToPdfMake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useLetterHeadStore } from "../../stores/letterHeadStore";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import updateReplyLetter from "../../data/v2/updateReplyLetter";
import SubmitModal from "../../UiKit/SubmitModal";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const LetterComponent = ({ patient, authorizationCase, denial }) => {
	// STATE
	const [isModalOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [appeal, setAppeal] = useState(denial.reply_letter);
	const [height, setHeight] = useState("auto");
	const [isSaved, setIsSaved] = useState(true);
	const [saving, setSaving] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);

	// REF
	const letterContentRef = useRef(null);
	const textareaRef = useRef();

	// HOOKS
	const { letterHead, isFetchingLetterhead, init, hasFetched } =
		useLetterHeadStore();
	const updateLetter = useCallback(debounce(updateReplyLetter, 1000), []);

	const debouncedSetIsSaved = useCallback(
		debounce(() => {
			setIsSaved(true);
			setSaving(false);
		}, 1000),
		[]
	);
	const location = useLocation();
	const navigate = useNavigate();

	// FUNCTIONS
	const toggleModal = () => setIsOpen(!isModalOpen);

	const handleSendAppeal = async () => {
		setLoading(true);
		try {
			const filePath = await generatePDF();
			if (filePath === null) {
				toast("Error sending appeal", { type: "error" });
			}

			console.log("denial.id:", denial.id);

			await sendFax(filePath, denial.id, authorizationCase.id);
			setLoading(false);
			toggleModal();
			toast("Appeal sent successfully", { type: "success" });

			setTimeout(() => {
				navigate("/home");
			}, 1000);
		} catch (e) {
			setLoading(false);
			toast("Error sending appeal", { type: "error" });
		}
	};

	const convertToBlackAndWhite = async (imageSrc) => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.src = imageSrc;
			image.crossOrigin = "Anonymous";
			image.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = image.width;
				canvas.height = image.height;
				ctx.drawImage(image, 0, 0);
				const imageData = ctx.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);
				const data = imageData.data;
				for (let i = 0; i < data.length; i += 4) {
					const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
					data[i] = data[i + 1] = data[i + 2] = avg;
				}
				ctx.putImageData(imageData, 0, 0);
				resolve(canvas.toDataURL());
			};
			image.onerror = () => {
				reject(new Error("Error loading image"));
			};
		});
	};

	const generatePDF = async () => {
		const letterContent = textareaRef.current.innerHTML.replace(
			/\n/g,
			"<br>"
		);
		const formattedContent = htmlToPdfMake(letterContent);
		const backgroundImageUrl = letterHead?.letterHead;

		// WITH LETTERHEAD
		if (backgroundImageUrl) {
			const blackAndWhiteBackground = await convertToBlackAndWhite(
				backgroundImageUrl
			);

			const docDefinition = {
				content: [
					{
						text: "Appeal Letter",
						style: "header",
					},
					{
						text: formattedContent,
						style: "letterContent",
					},
				],
				pageSize: "LETTER",
				pageMargins: [40, 140, 40, 140],
				styles: {
					header: {
						fontSize: 18,
						bold: true,
						margin: [0, 0, 0, 20],
					},
					letterContent: {
						fontSize: 12,
					},
				},
				images: {
					background: blackAndWhiteBackground,
				},
				background: {
					image: "background",
					width: 612, // The width of a LETTER size page in points
					height: 792, // The height of a LETTER size page in points
					fit: [612, 792],
				},
			};

			const pdfDoc = pdfMake.createPdf(docDefinition);
			pdfDoc.download("Appeal_Letter.pdf");

			return new Promise((resolve) => {
				pdfDoc.getBase64((data) => {
					resolve(data);
				});
			});
		} else {
			// NO LETTERHEAD
			const docDefinition = {
				content: [
					{
						text: "Appeal Letter",
						style: "header",
					},
					{
						text: formattedContent,
						style: "letterContent",
					},
				],
				pageSize: "LETTER",
				pageMargins: [72, 72, 72, 72],
				styles: {
					header: {
						fontSize: 18,
						bold: true,
						margin: [0, 0, 0, 20],
					},
					letterContent: {
						fontSize: 12,
					},
				},
			};

			const pdfDoc = pdfMake.createPdf(docDefinition);
			pdfDoc.download("Appeal_Letter.pdf");

			return new Promise((resolve) => {
				pdfDoc.getBase64((data) => {
					resolve(data);
				});
			});
		}
	};

	const handleTextChange = async (e) => {
		setAppeal(e.target.value);
		setIsSaved(false);
	};

	const handleHeightUpdate = () => {
		// if (isCompletedPA) return;
		textareaRef.current.style.height = "auto"; // Reset height to 'auto' before recalculating
		const { scrollHeight } = textareaRef.current;
		setHeight(scrollHeight + "px");
		textareaRef.current.style.height = scrollHeight + "px";
	};

	// USE EFFECTS
	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		if (!letterHead && !isFetchingLetterhead && !hasFetched) {
			initialize();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleHeightUpdate();
	}, []);

	useEffect(() => {
		if (!initialLoad) {
			setSaving(true);
			updateLetter(denial.id, appeal);
			debouncedSetIsSaved();
		} else {
			setInitialLoad(false);
		}
	}, [appeal, denial.id, initialLoad, updateLetter]);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!isSaved) {
				e.preventDefault();
				e.returnValue =
					"You have unsaved changes. Are you sure you want to leave?";
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isSaved, location]);

	// COMPONENTS
	const SavingComponent = () => {
		// if (isCompletedPA) return;

		if (saving) {
			return <p>Saving...</p>;
		}

		if (!isSaved) {
			return <p>You have unsaved changes</p>;
		}

		return <p>All changes saved</p>;
	};

	return (
		<div className="px-12 w-full max-w-6xl mx-auto">
			<ToastContainer />
			<div className="mt-7 flex justify-between items-center mb-3">
				<Heading>Appeal letter</Heading>
				<SavingComponent />
			</div>

			<div className="text-base flex flex-col relative leading-7 mt-7 shadow-xl border border-gray-200 px-8 py-12 mb-8 shadow-sm pb-12">
				<div
					className={`flex flex-col outline-none whitespace-pre-line`}
					ref={letterContentRef}
				>
					{/* {isCompletedPA ? (
						<div>{appeal}</div>
					) : ( */}
					<textarea
						ref={textareaRef}
						className="border-transparent focus:border-transparent focus:ring-0"
						style={{ height, resize: "none" }}
						onInput={handleHeightUpdate}
						value={appeal}
						onChange={handleTextChange}
					/>
					{/* )} */}
				</div>
			</div>

			{/* {!isCompletedPA && ( */}
			<div className="flex w-full justify-end">
				<Button variant="full" onClick={toggleModal}>
					Submit appeal -{">"}
				</Button>
			</div>
			{/* )} */}

			<div className="h-8" />

			<SubmitModal
				isOpen={isModalOpen}
				close={toggleModal}
				onSubmit={async () => {
					await handleSendAppeal();
				}}
				description={`Are you sure you want to submit this prior authorization for ${patient.first_name}, ${patient.last_name}?`}
				title="Confirm submit"
				submitText="Submit"
				loading={loading}
			/>

			{/* <AdditionalDocument num={3} /> */}
		</div>
	);
};

export default LetterComponent;
