import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { AiOutlineLoading } from "react-icons/ai";
import SendAnimation from "../Components/ui/SendAnimation";
import Lottie from "react-lottie-player";

import sentFile from "../assets/sentFile.json";
const SubmitModal = ({
	isOpen,
	close,
	onSubmit,
	title,
	description,
	submitText,
	icon = <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />,
	loading = false,
	color = "blue",
	children,
}) => {
	if (children) {
		return (
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={close}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-background1 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all">
									{children}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		);
	}

	if (color === "red") {
		return (
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={close}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-background1 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all max-w-lg">
									<div>
										<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-lightRed text-red">
											{icon}
										</div>
										<div className="mt-3 text-center sm:mt-5">
											<Dialog.Title
												as="h3"
												className="text-xl font-semibold leading-6"
											>
												{title}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-base text-olive">
													{description}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
										<Button
											variant="red-outline"
											onClick={close}
											disabled={loading}
										>
											Cancel
										</Button>
										<Button
											variant="red"
											onClick={onSubmit}
											disabled={loading}
										>
											{loading && (
												<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
											)}
											{submitText}
										</Button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		);
	}

	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={close}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-background1 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all max-w-lg">
								{/* {!loading && ( */}
								<>
									<div>
										<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-babyBlue">
											{icon}
										</div>
										<div className="mt-3 text-center sm:mt-5">
											<Dialog.Title
												as="h3"
												className="text-xl font-semibold leading-6"
											>
												{title}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-base text-olive">
													{description}
												</p>
											</div>
										</div>
									</div>

									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
										<Button
											variant="outline"
											onClick={close}
											disabled={loading}
										>
											Cancel
										</Button>
										<Button
											variant="full"
											onClick={onSubmit}
											disabled={loading}
										>
											{loading && (
												<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
											)}

											{submitText}
										</Button>
									</div>
								</>
								{/* )} */}
								{/* {!loading && (
									<Lottie
										animationData={sentFile}
										play
										style={{ width: 150, height: 150 }}
									/>
								)} */}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SubmitModal;
