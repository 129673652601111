// Pull uploaded files within the rightbar
import { BASE_URL } from "../../globals";
import fetch from "unfetch";

const getAdditionalFiles = async (id) => {
	const url = `${BASE_URL}/api/files/additional/get`;
	console.log("GETTING");

	try {
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `${localStorage.getItem("auth")}`,
			},
			body: JSON.stringify({
				id,
			}),
		});

		if (res.ok) {
			const data = await res.json();
			console.log("DATA:", data);

			return { status: true, data };
		} else {
			console.log("ERROR FETCHING ADDITIONAL FILES:", res);
			return { status: false };
		}
	} catch (e) {
		console.log("ERROR:", e);
		return { status: false };
	}
};
export default getAdditionalFiles;
