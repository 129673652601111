import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const signup = async (
	email,
	password,
	title,
	specialty,
	first_name,
	last_name,
	practice_name,
	joiningPractice,
	practiceCode
) => {
	const url = `${BASE_URL}/api/user/signup/signup`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email,
			password,
			title,
			specialty,
			first_name,
			last_name,
			practice_name,
			joiningPractice,
			practiceCode,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		let responseData = await res.json();
		return { status: false, error: responseData.error };
	}
};

export default signup;
