import fetch from "unfetch";

const signup = async (name, email) => {
	const url = `/api/waitlist/signup`;

	console.log("making request");
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			name,
			email,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		let responseData = await res.json();
		console.log(responseData);
		return { status: false, error: responseData.error };
	}
};

export default signup;
