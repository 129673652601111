import { create } from "zustand";
import getPatients from "../data/v2/getPatients";

export const usePatientsStore = create((set) => ({
	patients: [],
	setPriorAuthorizationDenials: (patients) => set((state) => ({ patients })),
	error: null,
	loading: true,
	init: async () => {
		try {
			const response = await getPatients();
			const { data } = response;

			set((state) => ({
				patients: data,
				error: null,
				loading: false,
			}));
		} catch (error) {
			set((state) => ({ error: error.message, loading: false }));
		}
	},
}));
