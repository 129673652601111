import React, { useEffect } from "react";
import { Button } from "../UiKit/Button";

const TimeoutPopup = ({ onClick, onInactive }) => {
	useEffect(() => {
		const interval = setInterval(onInactive, 60_000);
		return () => clearInterval(interval);
	}, [onInactive]);

	return (
		<div
			className="fixed z-[10000000] inset-0 overflow-y-auto flex items-center justify-center bg-black bg-opacity-30"
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true"
		>
			<div className="flex flex-col bg-white rounded-md items-center px-8 py-16">
				<p>
					Are you still there? For your security, you will be logged
					out in 1 minute.
				</p>

				<div className="mt-4">
					<Button variant="full" onClick={onClick}>
						I'm back
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TimeoutPopup;
