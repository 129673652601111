import React, { useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import uploadFile from "../../data/files/uploadFile";
import { Button } from "../../UiKit/Button";
import Modal from "../../UiKit/Modal";
import { ToastContainer, toast } from "react-toastify";
import { usePriorAuthorizationDenials } from "../../stores/priorAuthorizationDenialsStore";
import { Heading } from "../../UiKit/Heading";
import { AiOutlineLoading } from "react-icons/ai";

const Upload = () => {
	const [file, setFile] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const toggleModal = () => setIsModalOpen(!isModalOpen);
	const { init } = usePriorAuthorizationDenials();

	const handleUpload = async () => {
		setLoading(true);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = async () => {
			try {
				const base64Result = reader.result.split(",")[1];
				const { status } = await uploadFile(base64Result);

				if (status) {
					await init();
					setLoading(false);
					toggleModal();
					setFile(null);
					toast("File uploaded successfully", { type: "success" });
				} else {
					setLoading(false);
					toast("Error uploading file", { type: "error" });
				}
			} catch {
				setLoading(false);
				toast("Error uploading file", { type: "error" });
			}
		};

		reader.onerror = (error) => {
			console.log(error);
			setLoading(false);
			toast("Error uploading file", { type: "error" });
		};
	};

	const handleFileUpload = (e) => {
		setFile(e.target.files[0]);
		setIsModalOpen(true);
	};

	return (
		<div>
			<ToastContainer />

			{file && (
				<Modal isOpen={isModalOpen} close={toggleModal}>
					<div className="w-loginWidth flex flex-col">
						<Heading center>Upload file</Heading>
						<div className="mt-4">
							<p className="text-olive text-center">
								This process typically takes a full minute.
								We'll process and automatically assign the file
								to the correct patient.
							</p>
						</div>

						<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
							<Button
								variant="blue-outline"
								onClick={toggleModal}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								variant="blue"
								onClick={handleUpload}
								disabled={loading}
							>
								{loading && (
									<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
								)}
								Upload file
							</Button>
						</div>
					</div>
				</Modal>
			)}

			{/* <Modal
				isOpen={isModalOpen}
				close={toggleModal}
				onSubmit={handleUpload}
				title="Upload Denial"
				description={file.name}
				submitText="Upload"
				icon={<TbFileUpload className="text-lg" />}
				loading={loading}
			/> */}

			<Button variant="full" onFileUpload={handleFileUpload}>
				<TbFileUpload className="text-lg mr-2.5" /> Manually upload file
			</Button>
		</div>
	);
};

export default Upload;
