import React, { useRef } from "react";
import useKeypress from "react-use-keypress";

const FaxItem = ({
	onSelectItem,
	setActiveItemId,
	isActive,
	idx,
	children,
}) => {
	const lastMousePosition = useRef({ x: 0, y: 0 });
	const lastMouseMoveTime = useRef(Date.now());

	const handleMouseMove = (event) => {
		const now = Date.now();
		const elapsedTime = now - lastMouseMoveTime.current;
		lastMouseMoveTime.current = now;

		const deltaX = event.clientX - lastMousePosition.current.x;
		const deltaY = event.clientY - lastMousePosition.current.y;
		lastMousePosition.current = { x: event.clientX, y: event.clientY };

		const speedX = Math.abs(deltaX / elapsedTime);
		const speedY = Math.abs(deltaY / elapsedTime);

		if (speedX < speedY) {
			// Allow hover change when vertical speed is greater than horizontal speed
			setActiveItemId(idx);
		}
	};

	useKeypress("Enter", () => {
		if (isActive) {
			onSelectItem();
		}
	});

	return (
		<div
			onClick={onSelectItem}
			onMouseMove={handleMouseMove}
			className={` relative w-full rounded-none border-[0.5px] border-b-gray-300 py-2.5 mx-auto min-h-10 flex cursor-pointer duration-10 transition-shadow ${
				isActive
					? "text-primary bg-gray-50 border-l-[3px] border-l-primary shadow-sm"
					: " border-l-[3px]"
			}`}
		>
			<span
				className={`my-auto flex justify-between w-full  ${
					isActive && " text-textPrimary font-semibold"
				}`}
			>
				{children}
			</span>
		</div>
	);
};
export default FaxItem;
