import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const getDenials = async () => {
	const url = `${BASE_URL}/api/files/all`;
	const res = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
	});

	if (res.ok) {
		let faxes = await res.json();
		return { status: true, data: faxes.data };
	} else {
		return { status: false, data: "Error in fetch." };
	}
};

export default getDenials;
