/**
 *
 * @param {string} NotificationType ERROR, SUCCESS, INFO
 * @param {string} MessageHeader Bolded Notification Text
 * @param {string} MessageBody Notification Subtext
 */

import { useEffect, useState } from "react";
import {
	AiOutlineCheckCircle,
	AiOutlineClose,
	AiOutlineCloseCircle,
	AiOutlineExclamationCircle,
} from "react-icons/ai";

const EventNotification = ({
	NotificationType,
	MessageHeader,
	MessageBody,
	dispatch,
	id,
}) => {
	const [timeOutprogress, setTimeoutProgress] = useState(0);
	const [intervalId, setIntervalId] = useState(null);
	const [exit, setExitStatus] = useState(true);

	const HandleStartTimer = () => {
		const id = setInterval(() => {
			setTimeoutProgress((prev) => {
				if (prev < 100) {
					return prev + 0.1;
				}

				clearInterval(id);
				return prev;
			});
		}, 10);
		setIntervalId(id);
	};

	const HandlePauseTimer = () => {
		console.log("CALLED");
		clearInterval(intervalId);
	};

	const HandleCloseNotification = () => {
		HandlePauseTimer();
		setExitStatus(true);
		setTimeout(() => {
			dispatch({
				type: "REMOVE_NOTIFICATION",
				id: id,
			});
		}, 3000);
	};

	useEffect(() => {
		if (timeOutprogress === 100 || timeOutprogress > 100) {
			HandleCloseNotification();
		}
	}, [timeOutprogress]);

	useEffect(() => {
		HandleStartTimer();
		setExitStatus(false);
	}, []);

	return (
		<div
			onMouseEnter={HandlePauseTimer}
			onMouseLeave={HandleStartTimer}
			className={`flex flex-col w-[calc(25vw)] bg-white shadow-lg rounded-lg transition-all duration-300 relative ${
				!exit ? "ml-0" : "ml-[calc(120%)]"
			}`}
		>
			<div className="flex w-full rounded-tr-lg rounded-tl-lg border border-gray-300">
				<div className="p-3 border-r-2 border-r-sub-medium rounded-bl-lg">
					{NotificationType === "ERROR" ? (
						<AiOutlineCloseCircle className="text-4xl text-danger" />
					) : null}
					{NotificationType === "SUCCESS" ? (
						<AiOutlineCheckCircle className="text-4xl text-success" />
					) : null}
					{NotificationType === "INFO" ? (
						<AiOutlineExclamationCircle className="text-4xl text-information" />
					) : null}
				</div>
				<div className="relative grow pl-3 my-auto">
					<div className="text-sm font-bold">
						{MessageHeader || "Head"}
					</div>
					<div className="text-sm fond-light">
						{MessageBody || "This is the body"}
					</div>
				</div>
				<AiOutlineClose
					onClick={() => HandleCloseNotification()}
					className="absolute top-2 right-2 cursor-pointer"
				/>
			</div>
			<div
				style={{
					width: `${timeOutprogress}%`,
				}}
				className={`h-1 rounded-bl-md ${
					NotificationType === "ERROR"
						? "bg-red-400"
						: NotificationType === "SUCCESS"
						? "bg-green-200"
						: "bg-blue-200"
				}`}
			></div>
		</div>
	);
};

export default EventNotification;
