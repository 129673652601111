import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const UploadLetterHead = async (fileContent, fileName) => {
	const url = `${BASE_URL}/api/org/letterhead/upload`;

	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			filePath: fileContent,
			fileName,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false };
	}
};
export default UploadLetterHead;
