import React from "react";

const StatusIndicator = ({ status }) => {
	const statusIndicatorColor =
		status.toLowerCase() === "pending"
			? "bg-primary"
			: status.toLowerCase() === "approved" ||
			  status.toLowerCase() === "approval"
			? "bg-green"
			: "bg-red";

	return (
		<div className="flex items-center capitalize">
			{status}
			<div
				className={`w-2 h-2 rounded-full ml-2 ${statusIndicatorColor}`}
			/>
		</div>
	);
};

export default StatusIndicator;
