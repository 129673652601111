import React, { useState } from "react";
import { useForm } from "react-hook-form";
import signup from "../data/waitlist/signup";
import { useNotification } from "../hooks/useNotify";
import { AiOutlineLoading } from "react-icons/ai";

const WaitlistPage = () => {
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const notify = useNotification();

	const onSubmit = async (data) => {
		setLoading(true);
		const { name, email } = data;

		// CALLS SIGNUP FUNCTION
		const didJoinWaitlist = await signup(name, email);

		// HANDLES (UN)SUCCESFUL SIGNUPS
		if (didJoinWaitlist.status !== false) {
			notify({
				type: "SUCCESS",
				header: "You joined the waitlist",
				body: "Thanks for signing up. We'll let you know when a spot opens.",
			});

			reset();
		} else {
			notify({
				type: "ERROR",
				header: "Error",
				body: "Couldn't join waitlist - please try again later",
			});
		}

		setLoading(false);
	};

	return (
		<div className="w-full h-screen flex flex-col">
			{/* HEADER */}
			<div className="mx-auto my-auto w-1/3 text-center">
				Interested in getting early access? Enter your information below
				and we will add you to our waitlist!{" "}
			</div>
			<div className="border border-gray-300 -mt-24 rounded-md shadow-md flex flex-col items-center mx-auto my-auto w-1/3 px-4 py-8">
				<h1 className="text-xl font-bold">Intelliga Health Waitlist</h1>

				{/* WAITLIST FORM */}
				<form
					className="flex flex-col w-1/2"
					onSubmit={handleSubmit(onSubmit)}
				>
					<label className="mt-8">
						Name:
						<input
							{...register("name")}
							required
							type="text"
							className="w-full border border-gray-200 rounded-md px-3 py-3 mt-2"
						/>
					</label>
					<label className="mt-4">
						Email:
						<input
							{...register("email")}
							required
							type="email"
							className="w-full border border-gray-200 rounded-md px-3 py-3 mt-2"
						/>
					</label>

					{/* SUBMIT BUTTON - DISPLAYS LOADING STATE */}
					<button
						type="submit"
						className="rounded-md px-4 mx-auto h-12 bg-ctaBlue text-white hover:bg-ctaBlue/75 text-lg text-center transition-all mt-8 flex items-center"
					>
						{loading && (
							<AiOutlineLoading className="animate-spin mx-auto text-xl my-auto mr-4" />
						)}{" "}
						Join
					</button>
				</form>
			</div>
		</div>
	);
};

export default WaitlistPage;
