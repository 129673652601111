import React from "react";

const Pagination = ({
	totalItems,
	itemsPerPage,
	currentPage,
	onPageChange,
}) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handleButtonClick = (newPage) => {
		if (newPage < 1 || newPage > totalPages) return;
		onPageChange(newPage);
	};

	if (totalPages === 0) {
		return (
			<div className="flex justify-center h-10 items-center">
				<p className="text-sm">No results found</p>
			</div>
		);
	}

	return (
		<div className="w-full relative h-full flex">
			<div className="bottom-12 absolute w-full flex">
				<div className="flex justify-center items-center mt-4 mx-auto">
					<button
						className={` text-sm bg-primary text-white py-1 px-3 rounded-sm mr-2 ${
							currentPage === 1
								? "opacity-50 cursor-not-allowed"
								: ""
						}`}
						onClick={() => handleButtonClick(currentPage - 1)}
						disabled={currentPage === 1}
					>
						Prev
					</button>
					<div className=" text-sm">
						Page {currentPage} of {totalPages}
					</div>
					<button
						className={` text-sm bg-primary text-white py-1 px-3 rounded-sm ml-2 ${
							currentPage === totalPages
								? "opacity-50 cursor-not-allowed"
								: ""
						}`}
						onClick={() => handleButtonClick(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
