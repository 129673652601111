import React from "react";
import { Button } from "../../UiKit/Button";
import { Divider } from "../../UiKit/Divider";
import { Heading } from "../../UiKit/Heading";
import { Logo } from "../../UiKit/Logo";

// The signup form will display passed inputs + button within the nice form format. Will also show progress and title.
const SignupForm = ({
	title,
	step,
	children,
	handleGoBack = false,
	handleSubmit = () => {},
}) => {
	return (
		<form
			onSubmit={handleSubmit}
			className="w-signupForm relative flex flex-col items-center overflow-hidden shadow-md py-10 bg-white"
			id={step}
		>
			<div
				className={`w-${
					step === 3 ? "full" : `${step}/3`
				} self-start absolute left-0 top-0`}
			>
				<Divider color="primary" height="1" />
			</div>

			{handleGoBack && (
				<div className="self-start left-6 top-6 absolute">
					<Button variant="inline" onClick={handleGoBack}>
						{"<"}- Go back
					</Button>
				</div>
			)}

			<Logo />

			<div className="mt-4 px-20">
				<Heading center>{title}</Heading>
			</div>

			<p className="text-olive text-sm mt-1 mb-10">Step {step} of 3</p>
			{children}
		</form>
	);
};

export default SignupForm;
