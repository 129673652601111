import React, { useEffect, useRef, useState } from "react";
import { Heading } from "./Heading";
import Modal from "./Modal";
import { Button } from "./Button";
import { AiOutlineLoading } from "react-icons/ai";
import Select from "react-select";
import SubHeading from "../pages/shared/SubHeading";
import EditableSpan from "./EditableSpan";
import { usePatientsStore } from "../stores/patientsStore";
import recategorize from "../data/v2/recategorize";
import { ToastContainer, toast } from "react-toastify";

const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#fff",
	}),
	option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: isDisabled
			? null
			: isSelected
			? "#025cb8" // color for selected option
			: isFocused
			? "#E7E6E6" // color for option on mouse hover
			: null,
		color: isSelected ? "white" : "navyblue",
	}),
};

const AssignModal = ({
	isModalOpen,
	toggleModal,
	patientId,
	caseId,
	type,
	inbound_fax_id,
}) => {
	// Grab patients & connected cases from patients store
	const {
		patients: storePatients,
		init,
		loading,
		error,
	} = usePatientsStore();

	// Hold the value of the Select components
	const defaultPatientId = patientId;
	const defaultCaseId = caseId;
	const defaultType = type;

	const patientSelectRef = useRef(null);
	const caseSelectRef = useRef(null);

	// Used later for handling submission functionality
	const [isAssigning, setIsAssigning] = useState(false);

	// Sliders state
	const assignedTypes = ["denial", "approval", "other"];
	const [selectedAssignedType, setSelectedAssignedType] = useState(
		assignedTypes[assignedTypes.indexOf(type)]
	);
	const assignedPatients = ["Existing patients", "New patient"];

	const [selectedAssignedPatients, setSelectedAssignedPatients] = useState(
		type === "other" ? assignedPatients[1] : assignedPatients[0]
	);
	const assignedCasesOptions = ["Existing case", "Generate new case"];
	const [assignedCaseOption, setAssignedCaseOption] = useState(
		type === "other" ? assignedCasesOptions[1] : assignedCasesOptions[0]
	);

	// Store patient state
	const [patients, setPatients] = useState(null);
	const [cases, setCases] = useState(null);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedCase, setSelectedCase] = useState(null);

	// State for creating new patient
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	useEffect(() => {
		if (selectedAssignedPatients === "New patient") {
			setAssignedCaseOption("Generate new case");
		}
	}, [selectedAssignedPatients]);

	// Pulls in data
	useEffect(() => {
		if (storePatients?.length) return;

		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init, storePatients?.length]);

	useEffect(() => {
		if (storePatients) {
			const configuredPatients = storePatients?.map(
				({ patient, cases }) => ({
					value: patient.first_name + " " + patient.last_name,
					label: patient.first_name + " " + patient.last_name,
					patientId: patient.patient_id,
					cases,
				})
			);

			setPatients(configuredPatients);

			const defaultPatient =
				type === "other"
					? configuredPatients[0]
					: configuredPatients.filter(
							(patient) => patient.patientId === patientId
					  )[0];

			setSelectedPatient(defaultPatient);
		}
	}, [patientId, storePatients, type]);
	useEffect(() => {
		console.log(selectedPatient);
	}, [selectedPatient]);
	useEffect(() => {
		if (!selectedPatient) return;

		const patientCases = selectedPatient?.cases.map((c) => {
			return {
				value: c.case.requested_medication_or_service,
				label: c.case.requested_medication_or_service,
				caseId: c.case.id,
			};
		});

		setCases(patientCases);
		if (!patientCases || !patientCases[0]) {
			setAssignedCaseOption("Generate new case");
		}
		const defaultCase =
			type === "junk"
				? patientCases[0]
				: patientCases.filter((c) => c.caseId === caseId)[0];

		if (defaultCase) {
			setSelectedCase(defaultCase);
		} else {
			setSelectedCase(patientCases[0]);
		}
	}, [caseId, selectedPatient, selectedPatient?.cases, type]);

	useEffect(() => {
		console.log(defaultCaseId, defaultPatientId, defaultType);
	}, []);

	if (loading) {
		return;
	}

	if (error || !patients) {
		console.log(error, cases, patients);
		return (
			<p>
				Error. Cases: {cases?.length} Patients: {patients?.length}
			</p>
		);
	}

	const handleSubmit = async () => {
		setIsAssigning(true);
		const defaultObj = {
			caseId: defaultCaseId,
			type: defaultType,
			patientId: defaultPatientId,
		};

		const didCreateNewPatient = selectedAssignedPatients === "New patient";

		const isOther = selectedAssignedType === "other";

		let status;

		// If we're dealing with other, we should pass patientId and caseId null
		// If we create a new patient, we should pass patientId and caseId null
		const shouldGenerateNewCase =
			assignedCaseOption === "Generate new case";
		if (didCreateNewPatient || isOther) {
			const res = await recategorize(
				selectedAssignedType,
				null,
				null,
				{
					first_name: firstName,
					last_name: lastName,
				},
				inbound_fax_id,
				defaultObj
			);

			status = res.status;
		}

		// If we get to this point we're dealing with an appeal or denial for the same patient

		// If we should generate a new case then the caseId should be null
		else if (shouldGenerateNewCase) {
			const res = await recategorize(
				selectedAssignedType,
				selectedPatient.patientId,
				null,
				null,
				inbound_fax_id,
				defaultObj
			);

			status = res.status;
		} else {
			const res = await recategorize(
				selectedAssignedType,
				selectedPatient.patientId,
				selectedCase?.caseId,
				null,
				inbound_fax_id,
				defaultObj
			);
			status = res.status;
		}

		// If we get to this point we're dealing with an appeal or denial where we've selected an existing patient and caseId
		// We should pass everything but patient data

		setIsAssigning(false);

		if (status) {
			// toggleModal();
			toast.success("Succesfully recategorized!");
		} else {
			toast.error("Failed to recategorize");
		}
	};

	return (
		<>
			<ToastContainer />
			<Modal isOpen={isModalOpen} close={toggleModal}>
				<div className="w-loginWidth flex flex-col z-[99999]">
					<Heading center>Recategorize {type}</Heading>

					<SubHeading>Assigned type</SubHeading>
					<Slider
						options={assignedTypes}
						selected={selectedAssignedType}
						setSelected={setSelectedAssignedType}
					/>

					{selectedAssignedType !== "other" && (
						<>
							<div className="mt-4">
								<SubHeading>Assigned patient</SubHeading>
							</div>

							<Slider
								options={assignedPatients}
								selected={selectedAssignedPatients}
								setSelected={setSelectedAssignedPatients}
								disabledOptions={
									type === "other" ? ["Existing patient"] : []
								}
							/>

							{selectedAssignedPatients ===
							"Existing patients" ? (
								<Select
									ref={patientSelectRef}
									className="basic-single mt-4 z-[9999]"
									classNamePrefix="select"
									isDisabled={false}
									isLoading={false}
									isClearable
									isRtl={false}
									isSearchable
									name="color"
									options={patients}
									styles={customStyles}
									onChange={setSelectedPatient}
									value={selectedPatient}
								/>
							) : (
								<>
									<p className="text-sm text-olive mt-4">
										First name
									</p>
									<EditableSpan
										value={firstName}
										setValue={setFirstName}
									/>

									<p className="text-sm text-olive mt-4">
										Last name
									</p>
									<EditableSpan
										value={lastName}
										setValue={setLastName}
									/>
								</>
							)}

							<div className="mt-4">
								<SubHeading>Assigned case</SubHeading>
							</div>

							<Slider
								options={assignedCasesOptions}
								selected={assignedCaseOption}
								setSelected={setAssignedCaseOption}
								disabledOptions={
									selectedAssignedPatients === "New patient"
										? ["Existing case"]
										: !cases || !cases[0]
										? ["Existing case"]
										: []
								}
							/>

							{assignedCaseOption === "Existing case" && (
								<Select
									menuPlacement={"auto"}
									maxMenuHeight={512}
									ref={caseSelectRef}
									className="basic-single mt-4 z-[999]"
									classNamePrefix="select"
									isDisabled={false}
									isLoading={false}
									isClearable
									isRtl={false}
									isSearchable={false}
									name="color"
									options={cases}
									styles={customStyles}
									value={selectedCase}
									onChange={setSelectedCase}
								/>
							)}
						</>
					)}

					<div className="mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
						<Button
							variant="blue-outline"
							onClick={toggleModal}
							disabled={isAssigning}
						>
							Cancel
						</Button>
						<Button
							variant="blue"
							onClick={handleSubmit}
							disabled={isAssigning}
						>
							{isAssigning && (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							)}
							Recategorize
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

const Slider = ({ options, selected, setSelected, disabledOptions = [] }) => {
	const handleClick = (option) => {
		if (disabledOptions.includes(option)) return;
		setSelected(option);
	};

	return (
		<div className="flex w-128 h-10 text-sm  overflow-hidden">
			{options.map((option) => (
				<div
					className={`w-1/${
						options.length
					} flex justify-center   mx-1 items-center cursor-pointer transition-colors duration-100 font-bold uppercase ${
						selected === option
							? "bg-primary text-white"
							: "text-primary border border-gray-300 rounded-sm"
					}`}
					onClick={() => handleClick(option)}
					key={option}
				>
					{option}
				</div>
			))}
		</div>
	);
};

export default AssignModal;
