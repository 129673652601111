import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const getUser = async () => {
	const url = `${BASE_URL}/api/user/get`;
	const res = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("auth"),
		},
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: true, data: responseData };
	} else {
		return { status: false, data: "Error, invalid login." };
	}
};

export default getUser;
