import { create } from "zustand";
import getPendingAppeals from "../data/v2/getPendingAppeals";

export const usePendingAppealsStore = create((set) => ({
	pendingAppeals: [],
	setPendingAppeals: (pendingAppeals) => set((state) => ({ pendingAppeals })),
	error: null,
	loading: true,
	init: async () => {
		try {
			const response = await getPendingAppeals();
			const { data } = response;

			set((state) => ({
				pendingAppeals: data,
				error: null,
				loading: false,
			}));
		} catch (error) {
			set((state) => ({ error: error.message, loading: false }));
		}
	},
}));
