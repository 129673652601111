import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { HiOutlineClipboard } from "react-icons/hi";

const CopyableField = ({ children }) => {
	const handleCopy = () => {
		navigator.clipboard.writeText(children);
		setHastCopiedField(true);
	};
	const [hasCopiedField, setHastCopiedField] = useState(false);

	useEffect(() => {
		setTimeout(() => setHastCopiedField(false), 250);
	});

	return (
		<div
			className={`flex items-center bg-background1 px-6 py-2.5 rounded-sm shadow-inner ${
				hasCopiedField &&
				"animate-copyPulse transition-all duration-200"
			}`}
		>
			<p className="truncate mr-2.5 text-olive text-sm">{children}</p>
			<button onClick={handleCopy}>
				<HiOutlineClipboard />
			</button>
		</div>
	);
};

export default CopyableField;
