import React from "react";

const DateIcon = ({ ascending }) => {
	if (ascending) {
		return (
			<svg
				fill="none"
				viewBox="0 0 24 24"
				height="24"
				width="24"
				xmlns="http://www.w3.org/2000/svg"
				className="w-4 fill-navyBlue"
			>
				<path
					xmlns="http://www.w3.org/2000/svg"
					d="M17 10L12 16L7 10H17Z"
				></path>
			</svg>
		);
	}

	return (
		<svg
			fill="none"
			viewBox="0 0 24 24"
			height="24"
			width="24"
			xmlns="http://www.w3.org/2000/svg"
			className="w-4 fill-navyBlue"
		>
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M7 14L12 8L17 14L7 14Z"
			></path>
		</svg>
	);
};

export default DateIcon;
