import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const sendFax = async (filePath, denialId, authorizationCaseId) => {
	const url = `${BASE_URL}/api/files/send`;
	console.log("FILE PATH:", filePath);

	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({ filePath, denialId, authorizationCaseId }),
	});

	if (res.ok) {
		let responseData = await res.json();
		console.log(responseData);
		return { status: true, data: responseData };
	} else {
		return { status: false, data: "Error in fetch." };
	}
};

export default sendFax;
