import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const joinOrg = async (
	email,
	org_id,
	first_name,
	last_name,
	title,
	code,
	password
) => {
	const url = `${BASE_URL}/api/user/signup/join`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email,
			org_id,
			first_name,
			last_name,
			title,
			code,
			password,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		let responseData = await res.json();
		return { status: false, error: responseData.message };
	}
};

export default joinOrg;
