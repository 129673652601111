import React, { useContext, useEffect, useState } from "react";
import { Heading } from "../../UiKit/Heading";
import Item from "../../UiKit/Item";
import { Button } from "../../UiKit/Button";
import Pagination from "../../UiKit/Pagination";
import useKeypress from "react-use-keypress";
import Loader from "../../UiKit/Loader";
import SearchFilters from "../shared/SearchFilters";
import DateIcon from "../shared/DateIcon";
import Modal from "../../UiKit/Modal";
import SubHeading from "../shared/SubHeading";
import { UserContext } from "../../contexts/UserContext";
import { usePendingAppealsStore } from "../../stores/pendingAppealsStore";
import { AiOutlineLoading } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import abandonCase from "../../data/v2/abandonCase";
import getAppeal from "../../data/v2/getAppeal";
import { IoCloseOutline } from "react-icons/io5";

const PendingAppeals = () => {
	const { pendingAppeals, init, loading, error } = usePendingAppealsStore();
	const [activeItemID, setActiveItemID] = useState(0);
	const [displayedItems, setDisplayedItems] = useState(pendingAppeals);
	const [showAssignedItems, setShowAssignedItems] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const itemsPerPage = 10;

	const handlePageChange = (newPage) => {
		setActiveItemID(0);
		setCurrentPage(newPage);
	};

	const user = useContext(UserContext);
	const [ascending, setAscending] = useState(true);

	const handleToggleDate = () => {
		setAscending(!ascending);
		setDisplayedItems((prevItems) => {
			return [...prevItems].sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return ascending ? dateA - dateB : dateB - dateA;
			});
		});
	};

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const renderedInbox = displayedItems
		?.slice(startIndex, endIndex)
		.map(
			(
				{
					patient: { first_name, last_name },
					denialData: {
						date_sent,
						g_requested_medication_or_service,
					},
				},
				index
			) => {
				const date = new Date(date_sent);
				return (
					<Item
						isActive={index === activeItemID}
						setActiveItemId={setActiveItemID}
						idx={index}
						onSelectItem={() => {}}
					>
						<div className="font-normal text-sm text-ellipsis overflow-hidden flex-1 whitespace-nowrap flex justify-between my-auto">
							<div className="flex-1">
								<div className="ml-6">
									{first_name} {last_name}
									{activeItemID === index && " ->"}
								</div>
							</div>

							<div className="flex-1">
								{g_requested_medication_or_service}
							</div>

							<div className="flex-1 flex justify-end">
								<div className="mr-6">
									{date.toDateString()}
								</div>
							</div>
						</div>
					</Item>
				);
			}
		);

	const handleKeyDown = () => {
		let num = activeItemID;
		if (num === pendingAppeals.length - 1) {
			num = 0;
		} else {
			num = num + 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	const handleKeyUp = () => {
		let num = activeItemID;
		if (num === 0) {
			num = pendingAppeals.length - 1;
		} else {
			num = num - 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	useKeypress(["ArrowDown"], handleKeyDown);
	useKeypress(["ArrowUp"], handleKeyUp);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (e) => {
		const term = e.target.value;

		setSearchTerm(term);
		const searchIndex = term.length;

		let filteredAppeals = pendingAppeals.filter(
			({ patient_fname, patient_lname }) => {
				return (
					patient_fname
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					patient_lname
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					(patient_fname + " " + patient_lname)
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase())
				);
			}
		);

		setDisplayedItems(filteredAppeals);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (!user) return;
		if (!pendingAppeals) return;

		let sortedAppeals = [...pendingAppeals];
		if (!ascending) {
			sortedAppeals.sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return dateB - dateA;
			});
		}

		if (showAssignedItems) {
			setDisplayedItems(
				sortedAppeals.filter((appeal) => appeal.assigned_to === user.id)
			);
		} else {
			setDisplayedItems(sortedAppeals);
		}
		// Reset current page to 1 when showAssignedItems is toggled
		setCurrentPage(1);
		setActiveItemID(0);
	}, [pendingAppeals, showAssignedItems, user, ascending]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init]);

	if (loading) {
		return <Loader title="pending appeals" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	return (
		<>
			<div className="flex flex-1 flex-col max-w-6xl mx-auto mt-8 px-10 overflow-y-scroll">
				<div className="flex justify-between items-center mb-4">
					<Heading>Pending Appeals</Heading>
				</div>

				<SearchFilters
					searchTerm={searchTerm}
					handleSearch={handleSearch}
					setShowAssignedItems={setShowAssignedItems}
					showAssignedItems={showAssignedItems}
				/>

				<div className="bg-slate-100 flex flex-row justify-between text-gray-800 mb-1">
					<div className="text-sm flex-1 font-semibold w-full my-auto">
						<span className="ml-6"> Patient name</span>
					</div>
					<div className="text-sm flex-1 font-semibold text-center w-1/3 my-auto">
						Denied treatment
					</div>
					<div className="flex flex-1 justify-end w-1/3">
						<button
							className="flex cursor-pointer h-full space-x-2 mr-6"
							onClick={handleToggleDate}
						>
							<p className="text-sm font-semibold text-center my-auto">
								Date appealed
							</p>
							<span className="my-auto">
								<DateIcon ascending={ascending} />
							</span>
						</button>
					</div>
				</div>

				{renderedInbox}
				<Pagination
					totalItems={displayedItems?.length}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>

			<RightBar
				item={displayedItems ? displayedItems[activeItemID] : null}
			/>
		</>
	);
};

const RightBar = ({ item }) => {
	const [isAbandonPriorAuthModalOpen, setIsAbandonPriorAuthModalOpen] =
		useState(false);
	const [isAbandoningPriorAuth, setIsAbandoningPriorAuth] = useState(false);
	const [fax, setFax] = useState(null);
	const [isFetching, setIsFetching] = useState(false);

	const toggleAbandonPriorAuthModalOpen = () =>
		setIsAbandonPriorAuthModalOpen(!isAbandonPriorAuthModalOpen);

	const { init } = usePendingAppealsStore();

	if (!item)
		return (
			<div className="h-screen bg-background1 overflow-y-scroll px-10 w-sidebarRight">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>
				<p className="text-olive mt-4">Nothing to display</p>
			</div>
		);

	const {
		patient: { first_name, last_name },
		denialData: { g_requested_medication_or_service, id },
	} = item;

	const caseId = item.case.id;

	const handleAbandonPriorAuth = async () => {
		setIsAbandoningPriorAuth(true);
		const res = await abandonCase(caseId);

		if (!res.status) {
			setIsAbandoningPriorAuth(false);
			toast.error("Something went wrong");
		} else {
			await init();
			setIsAbandoningPriorAuth(false);
			toast.success("Abandoned prior auth");
			toggleAbandonPriorAuthModalOpen();
		}
	};

	const handleClickView = async () => {
		setIsFetching(true);

		const { data, status } = await getAppeal(id);
		if (status) {
			setFax(data.data.appeal.appeal_base64);
		} else {
			toast.error("Error fetching appeal");
		}
		setIsFetching(false);
	};

	return (
		<div className="h-screen bg-background1 overflow-y-scroll px-10 w-sidebarRight">
			<ToastContainer />
			{fax && (
				<div
					className="w-full h-full absolute top-0 left-0"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
					}}
				>
					<div
						className="left-6 top-6 text-2xl bg-red text-white duration-300 rounded-full absolute cursor-pointer hover:bg-red-600 p-2 z-50"
						onClick={() => {
							setFax(null);
						}}
					>
						<IoCloseOutline />
					</div>

					<embed
						showToolbar={false}
						src={`data:application/pdf;base64,${fax}#zoom=80&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
						className="mx-auto my-auto shadow-2xl w-full h-full"
					/>
				</div>
			)}

			<div className="mt-8">
				<Heading>At a glance</Heading>
			</div>

			<SubHeading>Patient name</SubHeading>
			<p>
				{first_name} {last_name}
			</p>

			<SubHeading>Denied treatment</SubHeading>
			<p>{g_requested_medication_or_service}</p>

			<SubHeading>Actions</SubHeading>
			<Button
				variant="blue"
				onClick={(e) => {
					e.stopPropagation();
					handleClickView();
				}}
			>
				{isFetching ? (
					<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
				) : (
					<ViewIcon />
				)}
				View appeal
			</Button>

			<div className="mt-4">
				<Button variant="red" onClick={toggleAbandonPriorAuthModalOpen}>
					<svg
						fill="none"
						viewBox="0 0 24 24"
						height="24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
						className="w-4 fill-white mr-2"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
						></path>
					</svg>
					Abandon prior auth
				</Button>
			</div>

			<Modal
				isOpen={isAbandonPriorAuthModalOpen}
				close={toggleAbandonPriorAuthModalOpen}
			>
				<div className="w-loginWidth flex flex-col">
					<Heading center>Confirm abandon prior auth</Heading>

					<div className="bg-red w-16 h-16 flex items-center justify-center rounded-full my-4 self-center">
						<svg
							fill="none"
							viewBox="0 0 24 24"
							height="24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
							className="w-16 fill-white"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 11.5C9.55228 11.5 10 11.0523 10 10.5C10 9.94772 9.55228 9.5 9 9.5C8.44772 9.5 8 9.94772 8 10.5C8 11.0523 8.44772 11.5 9 11.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M11 10.5C11 9.94772 11.4477 9.5 12 9.5H15C15.5523 9.5 16 9.94772 16 10.5C16 11.0523 15.5523 11.5 15 11.5H12C11.4477 11.5 11 11.0523 11 10.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5H15C15.5523 14.5 16 14.0523 16 13.5C16 12.9477 15.5523 12.5 15 12.5H12Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5H15C15.5523 17.5 16 17.0523 16 16.5C16 15.9477 15.5523 15.5 15 15.5H12Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M10 13.5C10 14.0523 9.55228 14.5 9 14.5C8.44772 14.5 8 14.0523 8 13.5C8 12.9477 8.44772 12.5 9 12.5C9.55228 12.5 10 12.9477 10 13.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 17.5C9.55228 17.5 10 17.0523 10 16.5C10 15.9477 9.55228 15.5 9 15.5C8.44772 15.5 8 15.9477 8 16.5C8 17.0523 8.44772 17.5 9 17.5Z"
							></path>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M9 2C8.44772 2 8 2.44772 8 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3H16C16 2.44772 15.5523 2 15 2H9ZM16 5H18V20H6V5H8V6C8 6.55228 8.44772 7 9 7H15C15.5523 7 16 6.55228 16 6V5ZM10 5V4H14V5H10Z"
							></path>
						</svg>
					</div>

					<p className="mt-4 text-olive text-base text-center">
						Make sure you want to give up on this prior
						authorization
					</p>

					<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
						<Button
							variant="red-outline"
							onClick={toggleAbandonPriorAuthModalOpen}
							disabled={isAbandoningPriorAuth}
						>
							Cancel
						</Button>
						<Button
							variant="red"
							onClick={handleAbandonPriorAuth}
							disabled={isAbandoningPriorAuth}
						>
							{isAbandoningPriorAuth && (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							)}
							Abandon
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const ViewIcon = () => (
	<svg
		fill="none"
		viewBox="0 0 24 24"
		height="24"
		width="24"
		xmlns="http://www.w3.org/2000/svg"
		className="w-6 fill-white mr-2"
	>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
		></path>
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
		></path>
	</svg>
);

export default PendingAppeals;
