import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const updateDenial = async (
	denialId,
	caseId,
	requestedMedicationOrService,
	replyNumber,
	denialReason
) => {
	const url = `${BASE_URL}/api/denial/update`;
	const res = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			authorization: localStorage.getItem("auth"),
		},
		body: JSON.stringify({
			id: denialId,
			case_id: caseId,
			g_requested_medication_or_service: requestedMedicationOrService,
			g_reply_number: replyNumber,
			g_denial_reason: denialReason,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false, data: "Error in fetch." };
	}
};

export default updateDenial;
