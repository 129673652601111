import React, { useEffect, useState } from "react";
import login from "../../data/user/login";
import verifySession from "../../data/user/verify";
import hero from "../../assets/logo_nw.svg";
import { AiOutlineLoading } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../UiKit/Logo";
import { Heading } from "../../UiKit/Heading";
import { Input } from "../../UiKit/Input";
import { Button } from "../../UiKit/Button";

const LoginPage = () => {
	const [showError, setShowError] = useState(false);
	const [loginLoading, setLoginLoading] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const updateEmail = (e) => setEmail(e.target.value);
	const updatePassword = (e) => setPassword(e.target.value);

	const navigate = useNavigate();

	useEffect(() => {
		verifySession().then((data) => {
			if (data) {
				navigate("/home");
			}
		});
	}, [navigate]);

	const handleLogin = async (e) => {
		e.preventDefault();

		setLoginLoading(true);
		const didLogin = await login(email, password);

		if (didLogin.status) {
			localStorage.setItem("auth", didLogin.data);
			navigate("/home");
		} else {
			setShowError(true);
		}

		setLoginLoading(false);
	};

	const displayError = async () => {
		setTimeout(() => {
			setShowError(false);
		}, 8000);
	};

	useEffect(() => {
		if (showError) {
			displayError();
		}
	}, [showError]);

	const handleBookDemoClick = () => {
		window.location =
			"https://calendly.com/intelliga/intelliga-health-demo";
	};

	return (
		<div className="w-full h-screen flex flex-col items-center justify-center">
			<img
				src="https://i.imgur.com/YJKffB9.png"
				alt="background here"
				className="absolute z-0 w-full h-full"
			/>
			<div className="w-loginWidth flex flex-col shadow-sm">
				<form
					onSubmit={handleLogin}
					className="mx-auto my-auto shadow-md shadow-gray-600 flex bg-white flex-col items-center space-y-4 w-full relative px-20 py-10"
					id="login"
				>
					<div className="w-2/3 mx-auto flex relative">
						{showError && (
							<div className=" absolute -top-28 w-full bg-red-400 flex flex-col px-2 py-2 text-center text-gray-100">
								Error in login!
							</div>
						)}
					</div>
					<Logo />
					<div className="font-semibold mx-auto text-2xl">Login</div>
					<div className="h-px" />
					<Input
						variant="search"
						placeholder="Email"
						type="email"
						fullWidth
						required
						value={email}
						onChange={updateEmail}
					/>
					<Input
						variant="search"
						placeholder="Password"
						type="password"
						fullWidth
						required
						value={password}
						onChange={updatePassword}
					/>
					<Button variant="full" fullWidth type="submit" form="login">
						{loginLoading && (
							<AiOutlineLoading className="animate-spin mx-auto text-xl my-auto" />
						)}{" "}
						{!loginLoading && <span>Go</span>}
					</Button>
					<div className="mx-auto flex flex-row w-full text-gray-400">
						<span className="h-px w-full bg-gray-400 my-auto rounded-md" />
						<span className="px-2">Or</span>
						<span className="h-px w-full bg-gray-400 my-auto rounded-md" />
					</div>
					<Button
						variant="outline"
						fullWidth
						onClick={() => navigate("/signup")}
					>
						Signup
					</Button>
					<div className="mt-8 w-full flex justify-center">
						<Button variant="inline" onClick={handleBookDemoClick}>
							Book a demo -{">"}
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};
export default LoginPage;
