// Use to upload file via upload additional rightbar
import { BASE_URL } from "../../globals";
import fetch from "unfetch";

const uploadAdditional = async (base64Result, id, name) => {
	const url = `${BASE_URL}/api/files/additional/add`;
	console.log("REQUEST BODY:", {
		base64Result,
		id,
		name,
	});
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			filePath: base64Result,
			id,
			name,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		const errData = await res.json();
		return { status: false, error: errData.error };
	}
};

export default uploadAdditional;
