import React from "react";
import { TbFileUpload } from "react-icons/tb";

const FileUpload = ({ handleFileUpload, accept = "application/pdf" }) => (
	<div className="flex">
		<label
			for="dropzone-file"
			className="flex items-center text-primary text-base border border-primary rounded-sm py-2.5 px-4 hover:bg-primary hover:text-white justify-center transition-all  hover:cursor-pointer"
		>
			<TbFileUpload className="text-lg mr-2.5" /> Select file
		</label>
		<input
			id="dropzone-file"
			onChange={handleFileUpload}
			accept={accept}
			type="file"
			className="hidden"
		/>
	</div>
);

export default FileUpload;
