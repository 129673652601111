import { BASE_URL } from "../../globals";
import fetch from "unfetch";

const uploadFile = async (base64) => {
	// The absolute function url so we can surpass api gateway.
	// TODO: Move to ALB or EC2
	const url = `${BASE_URL}/api/files/upload`;
	// comment
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			base64,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false };
	}
};

export default uploadFile;
