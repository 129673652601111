import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { Heading } from "../../UiKit/Heading";
import { Button } from "../../UiKit/Button";
import Modal from "../../UiKit/Modal";
import EditableSpan from "../../UiKit/EditableSpan";
import { toast } from "react-toastify";
import updatePatientDetails from "../../data/v2/updatePatientDetails";
import updateDenial from "../../data/v2/updateDenial";
import TelInput from "../../UiKit/TelInput";

const ReplyPanel = ({
	authorizationCase,
	patient,
	denial,
	setShouldShowDenial,
	denialLetter,
}) => {
	const navigate = useNavigate();
	const [isPatientDetailsModalOpen, setIsPatientDetailsModalOpen] =
		useState(false);
	const togglePatientDetailsModal = () =>
		setIsPatientDetailsModalOpen(!isPatientDetailsModalOpen);
	const [isDenialModalOpen, setIsDenialModalOpen] = useState(false);
	const toggleDenialModal = () => setIsDenialModalOpen(!isDenialModalOpen);
	const phoneInput = useRef();
	const [modalFirstName, setModalFirstName] = useState(patient.first_name);
	const [modalLastName, setModalLastName] = useState(patient.last_name);
	const [modalMedication, setModalMedication] = useState(
		authorizationCase.requested_medication_or_service
	);
	const [modalReplyNumber, setModalReplyNumber] = useState(
		denial.g_reply_number
	);
	const [modalQuote, setModalQuote] = useState(denial.g_denial_reason);

	const [displayMedication, setDisplayMedication] = useState(
		authorizationCase.requested_medication_or_service
	);
	const [displayReplyNumber, setDisplayReplyNumber] = useState(
		denial.g_reply_number
	);
	const [displayQuote, setDisplayQuote] = useState(denial.g_denial_reason);
	const [displayPatientFname, setDisplayPatientFname] = useState(
		patient.first_name
	);
	const [displayPatientLname, setDisplayPatientLname] = useState(
		patient.last_name
	);
	const [isUpdatingPatient, setIsUpdatingPatient] = useState(false);
	const [isUpdatingDenial, setIsUpdatingDenial] = useState(false);

	const handleUpdatePatient = async () => {
		setIsUpdatingPatient(true);
		const response = await updatePatientDetails(
			patient.patient_id,
			modalFirstName,
			modalLastName
		);

		if (response.status) {
			setDisplayPatientFname(modalFirstName);
			setDisplayPatientLname(modalLastName);
			togglePatientDetailsModal();
			toast.success("Patient details updated successfully");
		} else {
			toast.error("Something went wrong");
		}

		setIsUpdatingPatient(false);
	};

	const handleUpdateDenial = async () => {
		setIsUpdatingDenial(true);
		const trimmedModalReplyNumber = modalReplyNumber?.trim();

		const response = await updateDenial(
			denial.id,
			denial.case_id,
			modalMedication,
			trimmedModalReplyNumber,
			modalQuote
		);

		if (response.status) {
			setDisplayMedication(modalMedication);
			setDisplayReplyNumber(modalReplyNumber);
			setDisplayQuote(modalQuote);
			toggleDenialModal();
			toast.success("Denial details updated successfully");
		} else {
			toast.error("Something went wrong");
		}

		setIsUpdatingDenial(false);
	};

	const date = new Date(denial.created_at);

	return (
		<div className="w-replyPageLeft h-full overflow-y-scroll bg-background1">
			<div className="my-8 ml-7">
				<Button onClick={() => navigate("/home")} variant="inline">
					{"<-"} Back home
				</Button>
			</div>

			<div className="px-7 mb-7">
				<div className="mt-8 mb-2">
					<Heading>Denial preview</Heading>
				</div>
				<embed
					src={`data:application/pdf;base64,${denialLetter}#&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
					style={{
						width: "100%",
						height: "360px",
					}}
					title="PDF viewer"
					className="mb-4 border border-b-2 border-primary"
				/>

				<Button
					variant="full"
					fullWidth
					onClick={() => setShouldShowDenial(true)}
				>
					<svg
						fill="white"
						viewBox="0 0 24 24"
						className="w-4 h-4 text-white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M17 2C16.4477 2 16 2.44772 16 3C16 3.55228 16.4477 4 17 4L18.5858 4L14.2929 8.29289C13.9024 8.68342 13.9024 9.31658 14.2929 9.70711C14.6834 10.0976 15.3166 10.0976 15.7071 9.70711L20 5.41422L20 7.00001C20 7.55229 20.4477 8 21 8C21.5523 8 22 7.55229 22 7L22 3C22 2.44772 21.5523 2 21 2H17ZM4 18.5858L4 17C4 16.4477 3.55228 16 3 16C2.44771 16 2 16.4477 2 17L2 21C2 21.5523 2.44772 22 3 22H7C7.55228 22 8 21.5523 8 21C8 20.4477 7.55229 20 7 20H5.41421L9.70711 15.7071C10.0976 15.3166 10.0976 14.6834 9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929L4 18.5858Z"
						></path>
					</svg>
					<span className="ml-2.5">Expand preview</span>
				</Button>

				{/* Another modal but for the details container */}
				<Modal isOpen={isDenialModalOpen} close={toggleDenialModal}>
					<div className="w-loginWidth flex flex-col">
						<Heading center>Denial details</Heading>

						<p className="text-sm text-olive mt-4">Medication</p>
						<EditableSpan
							value={modalMedication}
							setValue={setModalMedication}
						/>

						<p className="text-sm text-olive mt-4">Fax return</p>
						<TelInput SetState={setModalReplyNumber} />
						{/* <EditableSpan
			value={modalReplyNumber}
			setValue={setModalReplyNumber}
		/> */}

						<p className="text-sm text-olive mt-4">
							Reason for denial
						</p>
						<EditableSpan
							value={modalQuote}
							setValue={setModalQuote}
						/>

						<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
							<Button
								variant="outline"
								disabled={isUpdatingDenial}
								onClick={toggleDenialModal}
							>
								Cancel
							</Button>
							<Button
								variant="full"
								onClick={handleUpdateDenial}
								disabled={isUpdatingDenial}
							>
								{isUpdatingDenial && (
									<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
								)}
								Update denial
							</Button>
						</div>
					</div>
				</Modal>

				<div className="mt-8">
					<Heading>Denial details</Heading>
				</div>

				<DetailsContainer>
					<DisplayItem
						title="Medication"
						description={displayMedication}
					/>

					<DisplayItem
						title="Fax return"
						description={displayReplyNumber}
					/>
					<DisplayItem
						title="Reason for denial"
						description={displayQuote}
					/>
					<DisplayItem
						title="Received"
						description={date.toDateString()}
					/>

					<Button
						variant="full-thin"
						fullWidth
						onClick={toggleDenialModal}
					>
						<span className="ml-2.5">Update denial details</span>
					</Button>
				</DetailsContainer>

				<div className="mt-8">
					<Heading>Patient details</Heading>
				</div>

				<DetailsContainer>
					<div className="flex flex-wrap w-full justify-between">
						<DisplayItem
							title="First name"
							description={displayPatientFname}
						/>
						<DisplayItem
							title="Last name"
							description={displayPatientLname}
						/>
					</div>

					<Button
						variant="full-thin"
						fullWidth
						onClick={togglePatientDetailsModal}
					>
						<span className="ml-2.5">Update patient details</span>
					</Button>
				</DetailsContainer>

				<Modal
					isOpen={isPatientDetailsModalOpen}
					close={togglePatientDetailsModal}
				>
					<div className="w-loginWidth flex flex-col">
						<Heading center>Patient details</Heading>
						<p className="text-sm text-olive mt-4">First name</p>
						<EditableSpan
							value={modalFirstName}
							setValue={setModalFirstName}
						/>

						<p className="text-sm text-olive mt-4">Last name</p>
						<EditableSpan
							value={modalLastName}
							setValue={setModalLastName}
						/>

						<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
							<Button
								variant="outline"
								onClick={togglePatientDetailsModal}
								disabled={isUpdatingPatient}
							>
								Cancel
							</Button>
							<Button
								variant="full"
								onClick={handleUpdatePatient}
								disabled={isUpdatingPatient}
							>
								{isUpdatingPatient && (
									<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
								)}
								Update
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
};

const DisplayItem = ({ title, description }) => (
	<div className="flex flex-col">
		<p className="text-sm text-olive">{title}</p>
		<p className="text-base text-navyBlue font-bold mb-2">{description}</p>
	</div>
);

const DetailsContainer = ({ children }) => (
	<div className="p-4 mt-4 border border-olive border-px flex bg-gray-100 flex-col items-start">
		{children}
	</div>
);

export default ReplyPanel;
