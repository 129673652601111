import { useRef, useEffect } from "react";

const EditableSpan = ({ value, setValue }) => {
	const spanRef = useRef(null);
	const caretPosRef = useRef(0);

	const handleInput = (e) => {
		setValue(e.target.textContent);

		// Save the caret position
		const sel = window.getSelection();
		caretPosRef.current = sel.focusOffset;
	};

	// const handleKeyPress = (e) => {
	// 	// Stop Enter key from creating a new line
	// 	if (e.key === "Enter") {
	// 		e.preventDefault();
	// 		spanRef.current.blur(); // Remove focus from the span
	// 	}
	// };

	// Restore the caret position after updating the value
	useEffect(() => {
		if (spanRef.current.firstChild) {
			const range = document.createRange();
			const sel = window.getSelection();
			range.setStart(spanRef.current.firstChild, caretPosRef.current);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}
	}, [value]);

	return (
		<span
			ref={spanRef}
			contentEditable
			suppressContentEditableWarning
			onInput={handleInput}
			className="w-full flex items-center placeholder-olive rounded bg-background1 px-6 py-2.5 focus:outline-none outline-none border-none focus:border-transparent focus:ring-0"
		>
			{value}
		</span>
	);
};

export default EditableSpan;
