import fetch from "unfetch";

const refresh = async (id, field_to_refresh) => {
	const url = `/api/refresh/refresh`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({ id, field_to_refresh }),
	});

	if (res.ok) {
		let responseData = await res.json();
		console.log(responseData);
		return responseData;
	} else {
		return null;
	}
};

export default refresh;
