import { BASE_URL } from "../../globals";
import fetch from "unfetch";

const deleteAdditionalFile = async (file_id) => {
	const url = `${BASE_URL}/api/files/additional/delete`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			file_id,
		}),
	});

	if (res.ok) {
		return { status: true };
	} else {
		return { status: false };
	}
};
export default deleteAdditionalFile;
