import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const login = async (username, password) => {
	const url = `${BASE_URL}/api/login`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ username, password }),
	});

	if (res.ok) {
		let responseData = await res.json();
		console.log(responseData);
		localStorage.setItem("auth", responseData.token);
		return { status: true, data: responseData.token };
	} else {
		console.log("LOG IN ERROR: ", res);
		return { status: false, data: "Error, invalid login." };
	}
};

export default login;
