import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const OrgInfo = async (org_id) => {
	const url = `${BASE_URL}/api/org/info`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ org_id }),
	});

	if (res.ok) {
		return await res.json();
	} else {
		return null;
	}
};

export default OrgInfo;
