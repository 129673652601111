import React from "react";
import { Input } from "../../UiKit/Input";

const SearchFilters = ({
	searchTerm,
	handleSearch,
	setShowAssignedItems,
	showAssignedItems,
	hideShowAssignedItems = false,
	placeholder = "Search by patient name",
}) => {
	return (
		<div className="flex items-center justify-between mb-4">
			<div className="w-full text-xs">
				<Input
					value={searchTerm}
					onChange={(e) => handleSearch(e)}
					placeholder={placeholder}
					additionalStyling="text-xs"
				/>
			</div>
			{!hideShowAssignedItems && false && (
				<div className="ml-8 w-96 flex items-center">
					<label htmlFor="toggle-checkbox" className="mr-2">
						Only show assigned items
					</label>
					<input
						type="checkbox"
						id="toggle-checkbox"
						className="form-checkbox h-4 w-4 text-primary border-gray-300 rounded"
						onChange={() =>
							setShowAssignedItems(!showAssignedItems)
						}
						checked={showAssignedItems}
					/>
				</div>
			)}
		</div>
	);
};

export default SearchFilters;
