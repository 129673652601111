import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Input } from "../../UiKit/Input";
import { Button } from "../../UiKit/Button";
import OrgInfo from "../../data/org/OrgInfo";
import joinOrg from "../../data/user/joinEmail";
import { FaSpinner } from "react-icons/fa";
import { GiSpinningWheel } from "react-icons/gi";
import { AiOutlineLoading } from "react-icons/ai";
import { useNotification } from "../../hooks/useNotify";

const EmailSignupPage = () => {
	const notify = useNotification();
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState("");
	const [orgId, setOrgId] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [title, setTitle] = useState("");
	const [orgName, setOrgName] = useState("Loading...");
	const [password, setPassword] = useState("");
	const [inviteCode, setInviteCode] = useState("");
	const [loading, setIsLoading] = useState(false);
	const updateFirstName = (e) => {
		console.log(e);
		setFirstName(e.target.value);
	};
	const updateLastName = (e) => {
		console.log(e);
		setLastName(e.target.value);
	};
	const updateTitle = (e) => {
		console.log(e);
		setTitle(e.target.value);
	};
	const updatePassword = (e) => {
		console.log(e);
		setPassword(e.target.value);
	};
	const fetchOrgInfo = async (org_id) => {
		const orgInfo = await OrgInfo(org_id);
		console.log(orgInfo);
		setOrgName(orgInfo?.org_name);
	};
	useEffect(() => {
		const emailParam = searchParams.get("email");
		const orgIdParam = searchParams.get("org_id");
		const invite_code = searchParams.get("invite_code");
		if (emailParam) {
			setEmail(emailParam);
		}
		if (invite_code) {
			setInviteCode(invite_code);
		}
		if (orgIdParam) {
			setOrgId(orgIdParam);
			fetchOrgInfo(orgIdParam);
		}
	}, [searchParams]);
	const joinOrgFunc = async (
		email,
		org_id,
		first_name,
		last_name,
		title,
		code,
		password
	) => {
		const didJoin = await joinOrg(
			email,
			org_id,
			first_name,
			last_name,
			title,
			code,
			password
		);
		if (!didJoin.status) {
			notify({
				type: "ERROR",
				header: "Unable to sign up",
				body: didJoin?.error,
			});
		} else {
			window.location.href = "https://app.intelligahealth.com";
		}
		setIsLoading(false);
	};
	return (
		<div className="w-full h-screen bg-blue-200 flex flex-col">
			{" "}
			<div className="mx-auto my-auto w-full md:w-1/2 shadow-2xl shadow-black-200 bg-white border-2 border-gray-200 py-2 flex flex-col rounded-md">
				33
				<div className="text-primary text-center mx-auto text-4xl pt-12">
					<span className="font-bold">INTELLIGA</span> HEALTH
				</div>
				<div className="mx-auto text-3xl py-2 text-navyBlue">
					Signup
				</div>
				<div className="mx-auto md:w-2/3 w-11/12 mt-4 flex flex-col space-y-8 text-lg h-full">
					<div className="w-full bg-gray-50 rounded-md text-opacity-30 text-gray-700 px-6 py-4">
						{email}
					</div>
					<div className="w-full bg-gray-50 rounded-md text-opacity-30 text-gray-700 px-6 py-4">
						{orgName}
					</div>
					<div className="flex space-x-2">
						<Input
							variant="search"
							placeholder="First Name"
							type="name"
							fullWidth
							required
							className="text-gray-900"
							value={firstName}
							onChange={updateFirstName}
						/>
						<Input
							variant="search"
							placeholder="Last Name"
							type="name"
							fullWidth
							required
							value={lastName}
							onChange={updateLastName}
						/>
					</div>
					<Input
						variant="search"
						placeholder="Title"
						type="name"
						fullWidth
						required
						value={title}
						onChange={updateTitle}
					/>
					<Input
						variant="search"
						placeholder="Password"
						type="password"
						fullWidth
						required
						value={password}
						onChange={updatePassword}
					/>
					<div className="mx-auto mt-8">
						{!loading && (
							<Button
								onClick={() => {
									setIsLoading(true);
									joinOrgFunc(
										email,
										orgId,
										firstName,
										lastName,
										title,
										inviteCode,
										password
									);
								}}
								variant="full"
							>
								Sign Up{" "}
							</Button>
						)}
						{loading && (
							<button className="bg-primary flex w-full px-2 py-2 rounded-md">
								<AiOutlineLoading className="text-white animate-spin mx-auto my-auto" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailSignupPage;
