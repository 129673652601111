import fetch from "unfetch";
import { BASE_URL } from "../../globals";

const GetLetterHead = async () => {
	const url = `${BASE_URL}/api/org/letterhead/get`;
	const res = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
	});

	if (res.ok) {
		let { data } = await res.json();

		return {
			status: true,
			data,
		};
	} else {
		return { status: false };
	}
};

export default GetLetterHead;
