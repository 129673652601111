import fetch from "unfetch";
import { BASE_URL } from "../../globals";

/**
 *
 * @returns
 */
const updateDenialField = async (field_name, value, fax_id) => {
	if (localStorage.getItem("auth")) {
		const url = `${BASE_URL}/api/files/updatefield`;
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `${localStorage.getItem("auth")}`,
			},
			body: JSON.stringify({
				field_name,
				fax_id,
				value,
			}),
		});

		if (res.ok) {
			const data = await res.json();
			console.log(data);
			return { status: true, data: data };
		} else {
			return { status: false };
		}
	}
};
export default updateDenialField;
