import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getUser from "../data/user/getUser";
import verifySession from "../data/user/verify";
import { BASE_URL } from "../globals";
import ActivateAccountPage from "../pages/ActivateAccountPage";

export const UserContext = createContext();
export const UserProvider = ({ children }) => {
	const navigate = useNavigate();
	const [user, setUser] = useState();

	useEffect(() => {
		verifySession().then((data) => {
			if (!data) {
				localStorage.clear();
				navigate("/");
			}
		});

		getUser().then((data) => {
			if (data.status) {
				setUser(data.data);
			}
		});

		window.addEventListener("storage", () => {
			const auth = localStorage.getItem("auth");
			if (auth === null) {
				navigate("/");
			}
		});
	}, [navigate]);

	const refreshUser = async () => {
		const data = await getUser();

		if (data.status) {
			setUser(data.data);
		}

		return;
	};

	const updateUser = async (firstName, lastName, email) => {
		const url = `${BASE_URL}/api/user/update`;
		const res = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				authorization: `${localStorage.getItem("auth")}`,
			},
			body: JSON.stringify({ firstName, lastName, email }),
		});

		if (res.ok) {
			await refreshUser();

			return {
				status: true,
			};
		} else {
			return { status: false };
		}
	};

	return (
		<UserContext.Provider value={{ user, refreshUser, updateUser }}>
			{user?.orgs?.did_book_demo === false ? (
				<ActivateAccountPage />
			) : (
				children
			)}
		</UserContext.Provider>
	);
};
