import { create } from "zustand";
import getDenials from "../data/v2/getDenials";

export const usePriorAuthorizationDenials = create((set) => ({
	prior_authorization_denials: [],
	setPriorAuthorizationDenials: (denials) =>
		set((state) => ({ prior_authorization_denials: denials })),
	error: null,
	loading: true,
	init: async () => {
		try {
			const response = await getDenials();
			const { status, data } = response;

			if (status) {
				set((state) => ({
					prior_authorization_denials: data,
					error: null,
					loading: false,
				}));
			} else {
				set((state) => ({
					prior_authorization_denials: [],
					error: true,
					loading: false,
				}));
			}
		} catch (error) {
			set((state) => ({ error: error.message, loading: false }));
		}
	},
}));
