import React from "react";
import { FiRefreshCw } from "react-icons/fi";

const variants = {
	search: "w-full flex items-center placeholder-olive rounded bg-background1 px-4 py-2.5 focus:outline-none outline-none border-none focus:border-transparent focus:ring-0",
	searchWhite:
		"w-full flex items-center placeholder-olive rounded bg-white px-6 py-2.5 focus:outline-none outline-none border-none focus:border-transparent focus:ring-0",
	denial: "w-full placeholder-[navyBlue/50] rounded bg-background2 px-3 py-2 text-navy focus:outline-none",
};

export const Input = ({
	value,
	onChange,
	placeholder,
	onBlur,
	variant = "search",
	handleRefresh = false,
	fullWidth,
	reference = null,
	type = "text",
	required = false,
	disabled = false,
	additionalStyling = "",
}) => {
	return (
		<div
			className={`${additionalStyling} flex relative items-center ${
				fullWidth && "w-full"
			}`}
		>
			<input
				className={`${additionalStyling} ${variants[variant]} ${
					disabled && "cursor-not-allowed"
				}`}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				onBlur={onBlur}
				ref={reference}
				type={type}
				required={required}
				disabled={disabled}
			></input>
			{handleRefresh && (
				<button
					className="absolute right-4 top-3"
					onClick={handleRefresh}
				>
					<FiRefreshCw className="text-md" />
				</button>
			)}
		</div>
	);
};
