import React, { useContext, useEffect, useState } from "react";
import { Heading } from "../../UiKit/Heading";
import Item from "../../UiKit/Item";
import Pagination from "../../UiKit/Pagination";
import useKeypress from "react-use-keypress";
import Loader from "../../UiKit/Loader";
import SearchFilters from "../shared/SearchFilters";
import DateIcon from "../shared/DateIcon";
import Case from "../shared/Case";
import SubHeading from "../shared/SubHeading";
import { Button } from "../../UiKit/Button";
import Modal from "../../UiKit/Modal";
import { useLostCasesStore } from "../../stores/lostCasesStore";
import { UserContext } from "../../contexts/UserContext";
import makeCaseActive from "../../data/v2/makeCaseActive";
import { AiOutlineLoading } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";

const LostCasesPage = () => {
	const [activeItemID, setActiveItemID] = useState(0);
	const { lostCases, init, loading, error } = useLostCasesStore();
	const [displayedItems, setDisplayedItems] = useState(lostCases);
	const [showAssignedItems, setShowAssignedItems] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const handlePageChange = (newPage) => {
		setActiveItemID(0);
		setCurrentPage(newPage);
	};

	const { user } = useContext(UserContext);
	const [ascending, setAscending] = useState(true);

	const handleToggleDate = () => {
		setAscending(!ascending);
		setDisplayedItems((prevItems) => {
			return [...prevItems].sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return ascending ? dateA - dateB : dateB - dateA;
			});
		});
	};

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const renderedInbox = displayedItems
		?.slice(startIndex, endIndex)
		.map(
			(
				{
					lostCaseData: {
						requested_medication_or_service,
						date_lost,
					},
					patient: { first_name, last_name },
				},
				index
			) => {
				const date = new Date(date_lost);
				return (
					<Item
						isActive={index === activeItemID}
						setActiveItemId={setActiveItemID}
						idx={index}
						onSelectItem={() => {}}
						key={index}
					>
						<div className="font-normal text-sm text-ellipsis overflow-hidden flex-1 whitespace-nowrap flex justify-between my-auto">
							<div className="flex-1">
								<div className="ml-6">
									{first_name} {last_name}
									{activeItemID === index && " ->"}
								</div>
							</div>

							<div className="flex-1">
								{requested_medication_or_service}
							</div>

							<div className="flex-1 flex justify-end">
								<div className="mr-6">
									{date.toDateString()}
								</div>
							</div>
						</div>
					</Item>
				);
			}
		);

	const handleKeyDown = () => {
		let num = activeItemID;
		if (num === lostCases.length - 1) {
			num = 0;
		} else {
			num = num + 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	const handleKeyUp = () => {
		let num = activeItemID;
		if (num === 0) {
			num = lostCases.length - 1;
		} else {
			num = num - 1;
		}

		// check if newly selected item is within the range of displayed items
		if (num < startIndex || num >= endIndex) {
			return;
		}

		setActiveItemID(num);
	};

	useKeypress(["ArrowDown"], handleKeyDown);
	useKeypress(["ArrowUp"], handleKeyUp);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (e) => {
		const term = e.target.value;

		setSearchTerm(term);
		const searchIndex = term.length;

		let filteredLostCases = lostCases.filter(
			({ patient: { first_name, last_name } }) => {
				return (
					first_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					last_name
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase()) ||
					(first_name + " " + last_name)
						.substring(0, searchIndex)
						.toLowerCase()
						.includes(term.toLowerCase())
				);
			}
		);

		setDisplayedItems(filteredLostCases);
		setCurrentPage(1);
	};

	useEffect(() => {
		if (!user) return;
		if (!lostCases) return;

		let sortedAppeals = [...lostCases];
		if (!ascending) {
			sortedAppeals.sort((a, b) => {
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return dateB - dateA;
			});
		}

		if (showAssignedItems) {
			setDisplayedItems(
				sortedAppeals.filter((appeal) => appeal.assigned_to === user.id)
			);
		} else {
			setDisplayedItems(sortedAppeals);
		}
		// Reset current page to 1 when showAssignedItems is toggled
		setCurrentPage(1);
		setActiveItemID(0);
	}, [lostCases, showAssignedItems, user, ascending]);

	useEffect(() => {
		const initialize = async () => {
			await init();
		};

		initialize();
	}, [init]);

	if (loading) {
		return <Loader title="lost cases" />;
	}

	if (error) {
		return <p>Error: {error.message}</p>;
	}

	return (
		<>
			<div className="flex flex-1 flex-col max-w-6xl mx-auto mt-8 px-10 overflow-y-scroll">
				<div className="flex justify-between items-center mb-4">
					<Heading>Lost cases</Heading>
				</div>

				<SearchFilters
					searchTerm={searchTerm}
					handleSearch={handleSearch}
					setShowAssignedItems={setShowAssignedItems}
					showAssignedItems={showAssignedItems}
				/>

				<div className="bg-slate-100 flex flex-row justify-between text-gray-800 ">
					<div className="text-sm flex-1 font-semibold w-full my-auto">
						<span className="ml-6">Patient name</span>
					</div>
					<div className="text-sm flex-1 font-semibold text-center w-1/3 my-auto">
						Denied treatment
					</div>
					<div className="flex flex-1 justify-end w-1/3">
						<button
							className="flex cursor-pointer h-full space-x-2 mr-6"
							onClick={handleToggleDate}
						>
							<p className="text-sm font-semibold text-center my-auto">
								Date denied
							</p>
							<span className="my-auto">
								<DateIcon ascending={ascending} />
							</span>
						</button>
					</div>
				</div>

				{renderedInbox}
				<Pagination
					totalItems={displayedItems?.length}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>

			<RightBar
				item={displayedItems ? displayedItems[activeItemID] : null}
			/>
		</>
	);
};

const RightBar = ({ item }) => {
	const [isConvertToActiveModalOpen, setIsConvertToActiveModalOpen] =
		useState(false);
	const [isMakingActive, setIsMakingActive] = useState(false);

	const toggleConvertToActiveModal = () =>
		setIsConvertToActiveModalOpen(!isConvertToActiveModalOpen);

	const { init } = useLostCasesStore();

	const handleCovertToActive = async () => {
		const {
			lostCaseData: { id },
		} = item;

		setIsMakingActive(true);
		const res = await makeCaseActive(id);

		if (res.status) {
			await init();
			setIsMakingActive(false);
			toggleConvertToActiveModal();
			toast.success("Case converted to active");
		} else {
			setIsMakingActive(false);
			toast.error("Error converting case to active");
		}
	};

	if (!item)
		return (
			<div className="h-screen bg-background1 overflow-y-scroll px-10 w-sidebarRight">
				<div className="mt-8">
					<Heading>At a glance</Heading>
				</div>
				<p className="text-olive mt-4">Nothing to display</p>
			</div>
		);

	return (
		<div className="h-screen bg-background1 overflow-y-scroll px-10 pb-10 w-sidebarRight">
			<ToastContainer />
			<div className="mt-8">
				<Heading>Case details</Heading>
			</div>

			<Case
				timeline={item.timeline}
				requestedMedicationOrService={
					item.lostCaseData.requested_medication_or_service
				}
				patient={item.patient}
				caseId={item.lostCaseData.case_id}
			/>

			<SubHeading>Actions</SubHeading>
			<Button variant="blue" onClick={toggleConvertToActiveModal}>
				<svg
					fill="none"
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
					className="w-6 fill-white mr-2"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M12.7929 2.29289C13.1834 1.90237 13.8166 1.90237 14.2071 2.29289L17.2071 5.29289C17.5976 5.68342 17.5976 6.31658 17.2071 6.70711L14.2071 9.70711C13.8166 10.0976 13.1834 10.0976 12.7929 9.70711C12.4024 9.31658 12.4024 8.68342 12.7929 8.29289L14.0858 7H12.5C8.95228 7 6 9.95228 6 13.5C6 17.0477 8.95228 20 12.5 20C16.0477 20 19 17.0477 19 13.5C19 12.9477 19.4477 12.5 20 12.5C20.5523 12.5 21 12.9477 21 13.5C21 18.1523 17.1523 22 12.5 22C7.84772 22 4 18.1523 4 13.5C4 8.84772 7.84772 5 12.5 5H14.0858L12.7929 3.70711C12.4024 3.31658 12.4024 2.68342 12.7929 2.29289Z"
					></path>
				</svg>
				Convert to active
			</Button>

			<Modal
				isOpen={isConvertToActiveModalOpen}
				close={toggleConvertToActiveModal}
			>
				<div className="w-loginWidth flex flex-col">
					<Heading center>Confirm convert to active</Heading>

					<div className="bg-primary w-16 h-16 flex items-center justify-center rounded-full my-4 self-center">
						<svg
							fill="none"
							viewBox="0 0 24 24"
							height="24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
							className="w-16 fill-white"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								d="M12.7929 2.29289C13.1834 1.90237 13.8166 1.90237 14.2071 2.29289L17.2071 5.29289C17.5976 5.68342 17.5976 6.31658 17.2071 6.70711L14.2071 9.70711C13.8166 10.0976 13.1834 10.0976 12.7929 9.70711C12.4024 9.31658 12.4024 8.68342 12.7929 8.29289L14.0858 7H12.5C8.95228 7 6 9.95228 6 13.5C6 17.0477 8.95228 20 12.5 20C16.0477 20 19 17.0477 19 13.5C19 12.9477 19.4477 12.5 20 12.5C20.5523 12.5 21 12.9477 21 13.5C21 18.1523 17.1523 22 12.5 22C7.84772 22 4 18.1523 4 13.5C4 8.84772 7.84772 5 12.5 5H14.0858L12.7929 3.70711C12.4024 3.31658 12.4024 2.68342 12.7929 2.29289Z"
							></path>
						</svg>
					</div>

					<p className="mt-4 text-olive text-base text-center">
						Make sure you want to give up on this prior
						authorization
					</p>

					<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
						<Button
							variant="blue-outline"
							onClick={toggleConvertToActiveModal}
						>
							Cancel
						</Button>
						<Button variant="blue" onClick={handleCovertToActive}>
							{isMakingActive && (
								<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
							)}
							Confirm
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default LostCasesPage;
