import { create } from "zustand";
import getApprovals from "../data/v2/getApprovals";

export const usePriorAuthorizationApprovalsStore = create((set) => ({
	approvals: [],
	setPriorAuthorizationDenials: (approvals) =>
		set((state) => ({ prior_authorization_approvals: approvals })),
	error: null,
	loading: true,
	init: async () => {
		try {
			const response = await getApprovals();
			const { data } = response;

			set((state) => ({
				approvals: data,
				error: null,
				loading: false,
			}));
		} catch (error) {
			set((state) => ({ error: error.message, loading: false }));
		}
	},
}));
