import React from "react";
import { Logo } from "../UiKit/Logo";
import { BsInbox } from "react-icons/bs";
import { IoPersonOutline, IoCheckmarkDoneSharp } from "react-icons/io5";
import { NavigationItem } from "../UiKit/NavigationItem";
import { Button } from "../UiKit/Button";
import { useNavigate } from "react-router-dom";
import { Heading } from "../UiKit/Heading";

const ActivateAccountPage = () => {
	const navigate = useNavigate();

	const handleLogOut = () => {
		localStorage.clear();
		navigate("/");
	};

	const handleBookDemoClick = () => {
		window.location = "https://o0b3ml63k7v.typeform.com/to/pivuPZ6v";
	};

	const pages = [
		{
			name: "Inbox",
			icon: <BsInbox />,
			path: "/inbox",
		},
		{
			name: "Submitted",
			icon: <IoCheckmarkDoneSharp />,
			path: "/submitted",
		},
		{
			name: "Account",
			icon: <IoPersonOutline />,
			path: "/account",
		},
	];

	return (
		<div className="w-full h-screen bg-white flex relative">
			<div className="flex w-sidebarLeft h-full flex-col justify-between bg-background1 opacity-50">
				<div>
					<div className="mt-8 mb-4 ml-4">
						<Logo />
					</div>

					<div className="flex flex-col px-4">
						{pages.map((key, idx) => {
							return (
								<NavigationItem
									onClick={() => {}}
									isActive={idx === 0}
									icon={key.icon}
									name={key.name}
									disabled
								/>
							);
						})}
					</div>
				</div>
			</div>
			<div className="absolute bottom-8 left-7">
				<Button variant="inline" onClick={handleLogOut}>
					Sign out -{">"}
				</Button>
			</div>
			<div className="flex w-full flex-col items-center py-10">
				<Heading>Welcome to your Intelliga dashboard 👋</Heading>

				<iframe
					src="https://www.loom.com/embed/399651c64f84431eb0ada5db0f3b8d07"
					frameborder="0"
					webkitallowfullscreen
					mozallowfullscreen
					allowFullScreen
					title="welcome video"
					style={{ width: "800px", height: "450px" }}
					className="mt-8"
				/>
				<div className="mb-8 mt-16">
					<Heading>Book a demo to activate your account</Heading>
				</div>

				<Button variant="outline" onClick={handleBookDemoClick}>
					Activate account ->
				</Button>
			</div>
		</div>
	);
};

export default ActivateAccountPage;
