import React from "react";

const buttonStyles = {
	inline: "text-primary text-base hover:underline",
	"inline-white": "text-white text-base hover:underline z-10",
	outline:
		" flex items-center text-primary text-base border border-primary py-2.5 px-8 hover:bg-primary hover:text-white justify-center transition-all",
	red: " flex items-center text-base py-2 px-8 bg-red text-white justify-center transition-all hover:opacity-90 ",
	"red-outline":
		"flex items-center text-base py-2 px-8 bg-white text-red border border-red justify-center transition-all hover:bg-red hover:text-white hover:opacity-90 shadow",
	full: " flex items-center text-base py-2 px-8 bg-primary text-white justify-center transition-all hover:opacity-90 ",
	"full-thin":
		"flex items-center text-base py-1 px-8 bg-primary text-white justify-center transition-all hover:opacity-90",
	blue: " flex items-center text-base py-2 px-8 bg-primary text-white justify-center transition-all hover:opacity-90",
	"blue-outline":
		"flex items-center text-base py-2 px-8 bg-white text-primary border border-primary justify-center transition-all hover:bg-primary hover:text-white hover:opacity-90",
};

export const Button = ({
	variant,
	children,
	onClick,
	fullWidth,
	type = "button",
	form = "",
	ref = null,
	disabled = false,
	onFileUpload = null,
}) => {
	if (onFileUpload) {
		return (
			<label
				for="dropzone-file"
				className={`${buttonStyles[variant]} ${
					fullWidth && "w-full"
				} cursor-pointer`}
			>
				{children}
				<input
					id="dropzone-file"
					onChange={onFileUpload}
					accept="application/pdf"
					type="file"
					className="hidden"
				/>
			</label>
		);
	}

	return (
		<button
			onClick={onClick}
			className={`${buttonStyles[variant]} ${fullWidth && "w-full"}`}
			type={type}
			form={form}
			ref={ref}
			disabled={disabled}
		>
			{children}
		</button>
	);
};
